// INPUTS

.form-floating {
   .form-label {
      color: $c-ter-600;
      padding: .9rem 1rem;
      font-size: 13px;

      &::after {
         background-color: transparent !important;
      }
   }

   &.has-error {
      .form-label {
         font-weight: $fw-bold;
         color: $c-prim-dark;
      }

      .form-control {
         border-color: $c-prim-dark;
      }

      .input-timefield {
         border: 1px solid $c-prim-dark;
      }
   }
}

.form-floating>.form-control {
   padding: 1rem;
   height: 44px;
   min-height: 44px;
}

.form-floating>.form-control.textarea {
   height: inherit;
   border-color: $c-ter-400;
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
   opacity: 1;
   transform: scale(0.85) translateY(-0.6rem) translateX(0.15rem);
}

input[type=text],
input[type=number],
input[type=date],
input[type=time],
textarea,
.input-style {
   border: solid 1px $c-ter-400;
   background-color: $c-ter-200 !important;
   color: $c-ter-700 !important;
   border-radius: 4px;
   box-shadow: none !important;
   font-size: 13px;
   font-weight: $fw-regular;
   padding: 1rem;
   resize: none;

   &:focus {
      border: solid 1px $c-ter-400 !important;
      background-color: $c-ter-200 !important;
   }

   &::placeholder {
      color: $c-ter-600;
   }
}

input[type=date]::-webkit-calendar-picker-indicator {
   background: transparent;
   bottom: 0;
   color: transparent;
   cursor: pointer;
   height: auto;
   left: 0;
   position: absolute;
   right: 0;
   top: 0;
   width: auto;
}

input[type=time]::-webkit-calendar-picker-indicator {
   background: transparent;
   bottom: 0;
   color: transparent;
   cursor: pointer;
   height: auto;
   left: 0;
   position: absolute;
   right: 0;
   top: 0;
   width: auto;
}

/* placeholder text style */
input[type=date]::-webkit-datetime-edit-text,
input[type=date]::-webkit-datetime-edit-month-field,
input[type=date]::-webkit-datetime-edit-day-field,
input[type=date]::-webkit-datetime-edit-year-field,
input[type=time]::-webkit-datetime-edit-hour-field,
input[type=time]::-webkit-datetime-edit-minute-field {
   color: $c-ter-600;
   background-color: transparent;
}

.input-date-icon {
   width: 12px;
   height: 13px;
   position: absolute;
   top: 16px;
   background-image: url('./images/icons/icon-calendar.svg');
   background-repeat: no-repeat;
   right: 17px;
   pointer-events: none;
}

.input-time-icon {
   width: 13px;
   height: 13px;
   position: absolute;
   top: 16px;
   background-image: url('./images/icons/icon-clock.svg');
   background-repeat: no-repeat;
   right: 14px;
   pointer-events: none;
}

.form-control:disabled,
.form-control[readonly],
.form-check-input:disabled {
   opacity: .45;
}

.form-control.border-error {
   border-color: $c-prim-dark;
}

.input-type-text {
   &.input-group {

      .form-control,
      .form-control:focus {
         border-right: 0 !important;
         border-top-right-radius: 0;
         border-bottom-right-radius: 0;
      }

      .clear-input {
         cursor: pointer;
         border-color: $c-ter-400;
         background-color: $c-ter-200 !important;
         font-size: 12px;
         border-left: 0;

         &.disabled {
            border-left: 0;
            cursor: default;
            pointer-events: none;
            opacity: .45;
         }
      }

   }

}

.base-checkbox {
   margin-top: .06rem;
   margin-right: .5rem;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   width: 20px;
   height: 20px;
   border: solid 1px $c-ter-400;
   border-radius: 5px;
   place-content: center;
   cursor: pointer;
   font-family: "fonticon";
   line-height: 1;
   display: inline-flex;
   outline: none !important;
   box-shadow: none !important;
   color: #fff;
   background-color: $c-ter-200;
   transition: 0.2s background-color linear;

   &:checked {
      border-color: $c-sec;
      background-color: $c-sec;
   }

   &::before {
      content: "";
      width: 20px;
      height: 20px;
      font-size: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
   }

   &:checked::before {
      content: '\e81f';
      background-color: $c-sec;
      border: none;
      position: relative;
      top: -1px;
      border-radius: 5px;
   }
}

.input-type-checkbox {
   .form-check-input {
      @extend .base-checkbox;
   }

   &.stacked {

      opacity: 0.7;
      border-radius: 8px;
      border: solid 1px $c-ter-400;
      width: fit-content;
      display: inline-flex;
      margin: 0 10px 10px 0;
      user-select: none;

      .form-label {
         margin-bottom: 0;
         padding: 12px 14px;
         cursor: pointer;
      }

      &.checked,
      &:hover {
         border-color: $c-sec-dark;
         opacity: 1;
         color: $c-sec-dark;
      }

      &.reversed {
         .form-check-input {
            margin-right: 0;
            margin-left: .7rem;
         }
      }

   }

   &.single {
      .form-check-input {
         margin-right: 0;
         margin-top: 0;
      }
   }

   .count-checkbox {
      position: relative;
      border-radius: 10px;
      color: #fff;
      font-size: 8px;
      top: -7px;
      left: -6px;
      padding: 3px 5px;
      background-color: $c-ter-600;
   }
}

.input-type-radio {

   .form-check-input {
      display: inline-flex;
      margin-top: .1rem;
      margin-right: .5rem;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 20px;
      height: 20px;
      border: solid 1px $c-ter-400;
      border-radius: 50%;
      place-content: center;
      cursor: pointer;
      outline: none !important;
      box-shadow: none !important;
      background-color: transparent;
      transition: 0.2s all linear;
      color: #fff;

      &:checked {
         border: 7px solid $c-sec;
         outline: unset !important
      }

   }

}

.input-type-switch {
   display: inline-block;

   .form-check-input {
      opacity: 0;
      width: 0;
      height: 0;
   }

   .disabled {
      .slider {
         opacity: .45;
      }
   }

   .slider {
      width: 32px;
      height: 20px;
      cursor: pointer;
      background-color: $c-ter-400;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 10px;
      display: inline-flex;
      margin-top: .1rem;


      &.normal {
         margin-right: .5rem;
      }

      &.invers {
         margin-left: .5rem;
      }
   }

   .slider:before {
      position: relative;
      content: "";
      height: 16px;
      width: 16px;
      left: 2px;
      top: 2px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
   }

   input:checked+.slider {
      background-color: $c-sec;
   }

   input:checked+.slider:before {
      -webkit-transform: translateX(11px);
      -ms-transform: translateX(11px);
      transform: translateX(11px);
   }

}

.input-type-date {

   .form-floating {
      .form-control {
         height: 44px;

         &.has-value {
            padding-top: 24px;
         }
      }

      .form-label {
         display: none;
         transition: 0.1s all linear;

         &.has-value {
            display: block;
            transform: scale(0.85) translateY(-0.6rem) translateX(0.15rem);
         }
      }

      .react-datepicker-wrapper {
         width: 100%;
      }
   }

   &.input-group {
      flex-wrap: nowrap;

      .form-control {
         border-right: 0 !important;
         border-top-right-radius: 0;
         border-bottom-right-radius: 0;
      }

      .clear-input {
         cursor: pointer;
         border-color: $c-ter-400;
         background-color: $c-ter-200 !important;
         font-size: 11px;
         padding: 5px 10px 5px 0;
         border-left: 0px;
      }

   }

   &.disabled {

      .input-date-icon,
      .clear-input {
         opacity: .45;
         pointer-events: none;
      }
   }

}

.multi-button {
   width: fit-content;
   display: inline-flex;
   justify-content: center;
   align-items: center;

   .dropdown {
      .btn-prim-dark {
         &:hover {
            color: #fff;
         }

         i {
            position: relative;
            top: 2px;
            left: 5px;
         }
      }
   }

   .upload-button {
      display: none;
   }
}

.react-datepicker-popper {
   z-index: 3 !important;

   .react-datepicker__triangle {
      color: #fff !important;
      fill: #fff !important;
   }
}

.calendar-dates {
   border-color: $c-ter-400 !important;
   font-family: 'Lato' !important;
   border-radius: 8px !important;
   box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.04);

   .react-datepicker__triangle {
      display: none;
   }

   .react-datepicker__navigation {
      top: 14px;
      border-radius: 8px;
      background-color: $c-gray-20;
      padding: 8px;
      margin: 0 1rem;
   }

   .react-datepicker__navigation-icon {
      &::before {
         top: 12px;
         border-width: 1.8px 1.8px 0 0;
         width: 7px;
         height: 7px;
         border-color: $c-gray-60;
      }
   }

   .react-datepicker__navigation-icon--previous {
      &::before {
         right: -3px;
         top: 9px;
      }
   }

   .react-datepicker__navigation-icon--next {
      &::before {
         left: -3px;
         top: 9px;
      }
   }

   .react-datepicker__day-name,
   .react-datepicker__day,
   .react-datepicker__time-name {
      margin: .3rem;
      width: 2rem;
      line-height: 2rem;
      color: $c-gray-90;
   }

   .react-datepicker__header {
      background-color: transparent;
      border-bottom: 0;

      .react-datepicker__current-month {
         color: $c-gray-90;
         text-transform: capitalize;
         font-size: 15px;
      }

      .react-datepicker__day-name {
         text-transform: uppercase;
         color: $c-gray-60 !important;
      }
   }

   .react-datepicker__today-button {
      background-color: $c-gray-5;
      border-top: 1px solid $c-gray-40;
      color: $c-gray-90;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      padding: 12px;
      font-size: 13px;
   }

   .react-datepicker__month {
      margin: .39rem;
   }

   .react-datepicker__month-container {
      padding: 1rem;
   }

   .react-datepicker__day-names {
      margin-top: 5px;
   }

   .react-datepicker__day--keyboard-selected {
      background-color: transparent;
   }

   .react-datepicker__day--selected {
      background-color: $c-sec-light;
      color: $c-sec-dark;
      font-weight: bold;

      &:hover {
         background-color: $c-sec;
         color: $c-ter-700;
      }
   }

   .react-datepicker__day--disabled {
      opacity: .5;
   }

}

.input-type-time {

   &.input-group {
      flex-wrap: nowrap;

      input[type=time],
      .input-timefield {
         border-right: 0 !important;
         border-top-right-radius: 0;
         border-bottom-right-radius: 0;
      }

      .clear-input {
         cursor: pointer;
         border-color: $c-ter-400;
         background-color: $c-ter-200 !important;
         font-size: 11px;
         padding: 5px 10px 5px 0;
         border-left: 0px;
      }

   }

   &.disabled {

      .input-time-icon,
      .clear-input {
         opacity: .45;
         pointer-events: none;
      }
   }

}

.input-type-step {

   input::-webkit-outer-spin-button,
   input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
   }

   input[type=number] {
      -moz-appearance: textfield;
      appearance: textfield;
   }

   .form-control[readonly] {
      opacity: 1;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
   }

   .button-action {
      width: 44px;
      cursor: pointer;
      border-color: $c-ter-400;
      background-color: #fff !important;
      font-size: 12px;
      color: $c-ter-600;

      .fticon-minus {
         margin-left: 2px;
      }
   }

   &.mini-step {
      flex-wrap: nowrap;

      .form-control[readonly] {
         padding: 5px;
         width: 34px;
         text-align: center;
         font-size: 12px;
      }

      .button-action {
         width: 25px;
         font-size: 8px;
         display: flex;
         align-items: center;
         padding: 5px;
         font-weight: $fw-bold;
      }

   }

}

.input-row {
   padding: 13px 24px;
   border-radius: 8px;
   background-color: $c-ter-200;
   margin-bottom: 1rem;

   .head {
      font-weight: $fw-bold;
      font-size: 14px;
      color: $c-ter-700;
      margin-bottom: 1rem;
   }

   .icon {
      display: inline-flex;
      width: 30px;
      align-items: center;

      .fticon-tick {
         font-size: 10px;
      }

      .fticon-ticket {
         font-size: 16px;
      }
   }

   .field {
      display: inline-flex;
      width: calc(100% - 30px);

      .count {
         padding: 0 5px;
         height: 18px;
         border-radius: 10px;
         background-color: $c-ter-600;
         color: #fff;
         font-size: 11px;
         display: flex;
         justify-content: center;
         align-items: center;
         margin-top: 2px;
         margin-left: 10px;
      }
   }

   .input-type-switch {
      width: 100%;

      .mb-3 {
         margin-bottom: 0 !important;
      }

      .form-label {
         width: 100%;
         display: flex;
         align-items: center;
         justify-content: space-between;
         font-weight: $fw-bold;
         font-size: 14px;
         color: $c-ter-700;
         margin-bottom: 0;
      }

   }

   .disabled {
      pointer-events: none;
      opacity: .45;
   }


}

.input-delete {
   display: inline-flex;
   width: 100%;

   .fticon-close {
      color: $c-ter-700;
      font-size: 12px;
   }

   .input-type-text {
      width: 100%;
   }
}

// Select
.input-select__control {
   @extend .input-style;
   border: solid 1px $c-ter-400 !important;
   min-height: 44px !important;
   font-weight: $fw-regular !important;
   font-size: 13px !important;
   padding: .2rem 0 0 .4rem;
   margin-bottom: 1rem;

   .input-select__placeholder {
      color: $c-ter-600;
   }

   .input-select__single-value {
      font-weight: $fw-bold;
      margin-left: 3px;
      transition: top 0.1s ease-in-out, font-size 0.1s ease-in-out;
   }

   .input-select__multi-value {
      margin: 3px 3px 3px 0;
      background-color: transparent;
      border-radius: 4px;
      opacity: .8;
      min-width: 40px;
      max-width: 110px;

      .fticon-close {
         font-size: 7px;
         margin-right: 3px;

         &:hover {
            color: $c-ter-700;
         }
      }

      .no-remove {
         margin-right: 4px;
      }

   }

   .input-select__multi-value__remove:hover {
      background-color: transparent;
      color: $primary;
   }

   .input-select__value-container--is-multi {
      flex-wrap: nowrap;
      overflow: hidden;

      &.w-value {
         overflow: inherit;

         .input-select__placeholder {
            top: 0;
            font-size: 11px;
         }

         div:nth-child(2) {
            padding-left: 3px;
            margin-top: 14px;
         }
      }

      &.input-select__value-container--has-value {
         .input-select__placeholder {
            top: -1px !important;
         }

      }
   }

   &.input-select__control--is-focused {

      .input-select__input-container {
         margin: 0;
         padding-left: 3px !important;
      }

      .input-select__value-container--has-value {
         .input-select__placeholder {
            font-size: 11px;
            top: -14px !important;
         }
      }

   }

   .input-select__input-container {
      margin: 0 0 0 -1px;
      height: 19px;

      .input-select__input {
         border: none !important;
      }
   }


   .input-select__indicators {

      .input-select__indicator {
         height: 100%;
         border-right: 0;
         border-left: 0;
         color: $c-ter-700;
         font-size: 20px;
         line-height: 0;

         &.input-select__loading-indicator {
            font-size: 10px;
            display: flex;
            align-items: center;
         }
      }

      .input-select__clear-indicator {
         font-size: 10px;
         position: relative;
         top: 6px;
         right: -5px;

      }

      .input-select__indicator-separator {
         width: 0 !important;
      }

      .input-select__clear-indicator {
         padding-left: 0;
         padding-right: 0;
         border-top-right-radius: 0 !important;
         border-bottom-right-radius: 0 !important;
      }
   }

   &.select-has-error {
      border-color: $c-error !important;
   }

}

.input-select__menu {
   z-index: 4 !important;

   .input-select__menu-list {
      max-height: 200px;
      overflow-x: hidden;
   }
}

.select-grouped {

   .input-select__control {
      .input-select__value-container {

         .selecteds {
            padding-left: 3px;
            font-weight: bold;
            margin-top: 14px;
         }

         .input-select__multi-value {
            margin: 0;
            opacity: 1;
            min-width: inherit;

            &:first-child {
               margin-left: 3px;
            }

            .input-select__multi-value__label {
               padding: 0;
               font-weight: bold;
               font-size: 13px;
               color: $c-ter-700;
               margin-right: 3px;

               &::after {
                  content: ",";
               }
            }

            .input-select__multi-value__remove {
               display: none;
            }

         }

         div:nth-child(2) {
            padding-left: 3px;
         }

         .input-select__input-container {
            padding-left: 2px !important;
         }

         div.input-select__multi-value:last-of-type {
            .input-select__multi-value__label {
               &::after {
                  content: "";
               }
            }
         }

      }

      &.input-select__control--is-focused {
         .input-select__value-container--has-value {
            .input-select__placeholder {
               top: -1px !important;
            }
         }
      }
   }
}

.input-select--is-disabled {
   opacity: .5;
}


//create single
.input-create {

   .input-select__control {
      max-height: 44px;

      .input-select__input-container {
         margin: 0;
      }

      &.input-select__control--is-focused {

         .input-select__input-container {
            margin: 0;
            overflow: hidden;
         }

         .input-select__value-container {
            padding-top: 14px;

            .input-select__placeholder {
               font-size: 11px;
               top: -14px !important;
            }

            .input-select__input {
               border: none !important;
               margin-left: 2px !important;
            }

         }

         .input-select__value-container--has-value {

            .input-select__placeholder {
               top: -14px !important;
            }

            .input-select__input {
               background-color: transparent !important;
            }
         }

      }

   }

}

//create multiple
.input-create-select {

   .input-select__control {

      .input-select__input-container {
         margin: 0 0 0 2px !important;
         overflow: hidden;
      }

      .input-select__value-container--is-multi {
         flex-wrap: inherit;
         overflow: inherit;
      }


      &.input-select__control--is-focused {

         .input-select__value-container {

            .input-select__placeholder {
               font-size: 11px;
               top: -14px !important;
            }

            .input-select__input {
               border: none !important;
            }
         }

         .input-select__value-container--has-value {

            .input-select__placeholder {
               top: -14px !important;
            }

         }

      }

   }

   &:not(.w-arrow) {
      .input-select__indicators {
         display: none;
      }

      .input-select__control {
         min-height: 61px !important;
         padding: 12px 5px 5px 5px;

         &.input-select__control--is-focused {

            .input-select__value-container--is-multi {
               padding: 14px 8px 2px 8px;

               &.input-select__value-container--has-value {
                  .input-select__placeholder {
                     top: -4px !important;
                  }
               }
            }
         }


         .input-select__value-container--is-multi {

            &.input-select__value-container--has-value {
               .input-select__placeholder {
                  top: -4px !important;
               }
            }
         }
      }
   }


}

.select-has-error {
   .input-select__control {
      border-color: $c-error !important;
   }

   .input-select__placeholder {
      color: $c-error !important;
      font-weight: $fw-bold;
   }
}


.input-select__menu,
.maproom-select__menu {
   padding: 10px 10px;
   box-shadow: 0 2px 11px 0 rgba(191, 203, 215, 0.35) !important;
   border: solid 1px $c-ter-200;

   .input-select__menu-list {
      padding: 0 10px 0 0;
   }

   .input-select__option,
   .maproom-select__option {
      margin-bottom: .2rem;
      word-break: break-all;
      display: inline-flex;
      align-items: flex-end;

      label,
      span {
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
         width: 85%;
      }

      span {
         width: 100%;
      }

      &:last-child {
         margin-bottom: 0;
      }

      &.input-select__option--is-focused,
      &.input-select__option--is-selected,
      &.maproom-select__option--is-focused,
      &.maproom-select__option--is-selected {
         background-color: rgba($c-sec, .14);
         border-radius: 6px;
         color: $c-ter-700;
      }
   }

}




.input-type-color {

   .form-label {
      font-weight: $fw-bold;
      font-size: 14px;
      padding-left: 3px;
   }

   .custom-color-picker {

      &__color {
         width: 30px;
         height: 30px;
         border-radius: 50%;
         border: 1px solid $c-ter-400;
         display: inline-flex;
         margin-right: 15px;

         &.transparent {
            background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAADFJREFUOE9jZGBgEGHAD97gk2YcNYBhmIQBgWSAP52AwoAQwJvQRg1gACckQoC2gQgAIF8IscwEtKYAAAAASUVORK5CYII=") left center;
         }
      }

      &__sublabel {
         display: inline-flex;
         align-items: center;
         justify-content: space-between;
         width: calc(100% - 45px);

         .info {
            display: flex;
            flex-direction: column;

            .color-zone {
               font-size: 11px;
               color: $c-ter-600;
               margin-bottom: -3px;
            }

            .color-name {
               font-size: 13px;
               color: $c-ter-700;
               font-weight: $fw-bold;
            }

         }


         .color-arrow {
            font-size: 20px;
            display: inline-flex;
         }
      }

      &__swatch {
         width: 100%;
         height: 44px;
         border-radius: 4px;
         border: solid 1px $c-ter-400;
         background-color: $c-ter-200;
         display: flex;
         align-items: center;
         cursor: pointer;
         padding: 0px 6px 0 12px;
      }

      &__popover {
         position: absolute;
         z-index: 2000;
         right: 12%;
      }

      &__cover {
         position: fixed;
         top: 0;
         right: 0;
         bottom: 0;
         left: 0;
         background-color: transparent;
      }

      &__selector {
         position: relative;
         right: 20px;
         top: 10px;
         border-radius: 4px;
         padding: 15px;
         box-shadow: 0 2px 11px 0 rgba(191, 203, 215, 0.35);
         border: solid 1px $c-ter-200;
         background-color: #fff;

         .circle-picker {
            max-width: 190px !important;

            span {
               display: inline-flex;
               margin: 3px;

               .color {
                  cursor: pointer;
                  border: 3px solid #FFF;
                  border-radius: 18px;
                  box-shadow: none;
                  height: 32px;
                  width: 32px;
                  transition: 0.1s all linear;

                  &:hover,
                  &.active {
                     box-shadow: 0 0 0 1px $c-ter-400;
                  }

                  &.transparent {
                     background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAADFJREFUOE9jZGBgEGHAD97gk2YcNYBhmIQBgWSAP52AwoAQwJvQRg1gACckQoC2gQgAIF8IscwEtKYAAAAASUVORK5CYII=") left center;
                  }
               }
            }


         }
      }
   }

   &.no-m-bt {
      .form-group {
         margin-bottom: 1rem !important;
      }
   }

   .has-error {
      .form-label {
         color: $c-error;
      }

      .custom-color-picker {

         &__swatch {
            border-color: $c-error;
         }

         .info {

            .color-zone,
            .color-name {
               color: $c-error;
            }
         }
      }
   }

   &.is-compact {
      .custom-color-picker {
         width: fit-content;

         .custom-color-picker__color {
            margin-right: 5px;
         }
      }
   }

}

//Upload Image (Eventos)

.upload-image {

   img {
      max-width: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 16px;
   }

   .image-container {
      .card {
         .card-body {
            padding: 1rem !important;
         }
      }

      .noimage-cont {
         border: 1px dashed $c-ter-400;
         background-color: $c-ter-200;
         border-radius: 8px;
         width: 100%;
         display: flex;
         align-items: center;
         justify-content: center;

         i {
            color: $c-ter-500;
            font-size: 40px;
            padding: 83px 0;
         }
      }

      &.has-image {
         .card {
            .card-body {
               padding: 0 !important;
            }
         }

         .noimage-cont {
            border: none;
            border-radius: 16px;
         }
      }

      .actions {
         text-align: center;
         margin-top: 1.8rem;

         .btn {
            &:first-child {
               margin-right: 1rem;
            }
         }
      }
   }

   .ui-input {
      input {
         display: none;
      }

   }

}

.upload-image-invitacion {
   img {
      max-width: 40px;
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 10px;
   }

   .image-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .card {
         .card-body {
            padding: 1rem !important;
         }
      }

      .noimage-cont {
         border: 1px dashed $c-ter-400;
         background-color: $c-ter-200;
         border-radius: 8px;
         width: 40px;
         height: 40px;
         display: flex;
         align-items: center;
         justify-content: center;
         float: left;

         i {
            color: $c-ter-500;
            font-size: 20px;
         }
      }

      &.has-image {
         .card {
            .card-body {
               padding: 0 !important;
            }
         }

         .noimage-cont {
            border: none;
            border-radius: 16px;
         }
      }

      .col-image {
         display: flex;
         align-items: center;

         .txt-imagen {
            margin-left: .6rem;
            width: 80px;
            font-size: 13px;
            color: $c-ter-600;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
         }
      }

      .col-actions {

         .btn {
            &:first-child {
               margin-right: 5px;
            }

            &:last-child {
               padding: 10px 15px;
            }
         }
      }

      .imagen-vacia {
         border: 1px dashed $c-ter-400;
         background-color: $c-ter-200;
         border-radius: 8px;
         width: 100%;
         display: flex;
         align-items: center;
         justify-content: center;

         i {
            color: $c-ter-500;
            font-size: 21px;
         }
      }


   }

   .ui-input {
      input {
         display: none;
      }

   }



}

//Upload File (button)
.upload-button {

   .actions {
      margin-top: 1.8rem;
   }

   .file-name {
      margin-top: 1rem;
      color: $c-ter-600;
   }

   .ui-input {
      input {
         display: none;
      }

   }
}

.upload-file {

   .file-name {
      margin-top: 1rem;
      color: $c-ter-600;
   }

   .ui-input {
      input {
         display: none;
      }

   }

}

//dropdown button

.drop-button {
   position: relative;

   .drop-backdrop {
      position: fixed;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0);
      top: 0;
      left: 0;
      display: none;
   }

   .drop-content {
      display: none;
      position: absolute;
      top: 45px;
      left: 0;
      width: max-content;
      padding: 1rem 2rem;
      border-radius: 4px;
      box-shadow: 0 2px 11px 0 rgba(191, 203, 215, 0.35);
      border: solid 1px $c-ter-200;
      background-color: #fff;
      z-index: 2;

      &.opened {
         display: block;
      }

      ul {
         margin: 0;
         padding: 0;
         list-style: none;

         li {
            margin-bottom: .8rem;
            cursor: pointer;

            &.sel-filter {
               i {
                  color: $c-sucs;
                  margin-left: .5rem;
               }
            }

            &:hover {
               color: $c-ter-600;
            }

            &:last-child {
               margin-bottom: 0;
            }
         }
      }


   }
}