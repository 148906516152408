/*----------------------
        VARIABLES
------------------------*/

$c-mediaset: #012D5B;
$c-mediaset-grey: #B2B2B2;

$c-telecinco: #0099D4;
$c-cuatro: #E4151B;
$c-fdf: #45AC34;
$c-boing: #7FB8E5;
$c-energy: #2B2729;
$c-divinity: #E83B84;
$c-bemad: #2C4A9A;
$c-mtmad: #FFDE00;

// PALETA
$c-prim: #ff345b;
$c-prim-dark: #d90a44;
$c-prim-light: #ffa0ab;

$c-sec: #4598bf;
$c-sec-dark: #1376a5;
$c-sec-light: #9bcbe2;

$c-ter-700: #082042;
$c-ter-600: #657488;
$c-ter-500: #93a6b9;
$c-ter-400: #bfcbd7;
$c-ter-300: #e2e9f0;
$c-ter-200: #f2f4f7;
$c-ter-100: #f8f9fa;

$c-gray-90: #333333;
$c-gray-80: #575757;
$c-gray-70: #868686;
$c-gray-60: #a1a1a1;
$c-gray-50: #c1c1c1;
$c-gray-40: #d7d7d7;
$c-gray-30: #eaeaea;
$c-gray-20: #f3f3f3;
$c-gray-10: #f8f8f8;
$c-gray-5: #fcfcfc;

//mapColors new
$c-magenta: #EC108A;
$c-rose: #FF60B2;
$c-lilac: #BC60E6;
$c-purple: #8E2BA0;
$c-eggplant: #671C59;
$c-electricblue: #2950BA;
$c-airforce: #1376A5;
$c-skyblue: #3EBAD8;
$c-bluishgreen: #09727E;
$c-turquoise: #288B69;
$c-greenforest: #2BA000;
$c-green: #6DD845;
$c-limegreen: #95B213;
$c-oldgold: #B1961C;
$c-mustard: #FFB134;
$c-orange: #FF6F2F;
$c-red: #D90A44;
$c-garnet: #981024;
$c-gray: #9B9B9B;
$c-charcoalgray: #464646;

//mapColors DARK new
$c-magenta-dark: #A21573;
$c-rose-dark: #AF4B8E;
$c-lilac-dark: #824BB1;
$c-purple-dark: #632782;
$c-eggplant-dark: #481D51;
$c-electricblue-dark: #1E4093;
$c-airforce-dark: #0F5A85;
$c-skyblue-dark: #2C88A8;
$c-bluishgreen-dark: #08576B;
$c-turquoise-dark: #1D685C;
$c-greenforest-dark: #1F7715;
$c-green-dark: #4C9D44;
$c-limegreen-dark: #678322;
$c-oldgold-dark: #7A7028;
$c-mustard-dark: #AF8238;
$c-orange-dark: #AF5535;
$c-red-dark: #951143;
$c-garnet-dark: #69152D;
$c-gray-dark: #6B737E;
$c-charcoalgray-dark: #323A45;

//mapColor extra
$c-reduced: #2972fa;
$c-reduced-dark: #143E87;

$c-sucs-dark: $c-greenforest;
$c-sucs: $c-green;
$c-pend: $c-mustard;
$c-error: #D90A44;

//dashboard
$c-greenlight : #7dcb60;
$c-redlight: #ff6b6b;
$c-purplelight: #7f77e0;

// ETIQUETAS
$et-send : $c-sucs;
$et-draft : $c-ter-500;
$et-open : $c-sucs-dark;
$et-bounce : $c-error;
$et-pend : $c-sec;

//------------

//fixed Colors
$c-blue: $c-sec;
$c-blue-dark: $c-sec-dark;
$bg-blue: $c-blue;
$c-mds-blue: #1d3868;

//---------------------------------------------

// generic loOoOops
$mainColors: (
        "blue": $c-blue,
        "magenta": $c-magenta,
        "rose": $c-rose,
        "lilac": $c-lilac,
        "purple": $c-purple,
        "eggplant": $c-eggplant,
        "electricblue": $c-electricblue,
        "airforce": $c-airforce,
        "skyblue": $c-skyblue,
        "bluishgreen": $c-bluishgreen,
        "turquoise": $c-turquoise,
        "greenforest": $c-greenforest,
        "green": $c-green,
        "limegreen": $c-limegreen,
        "oldgold": $c-oldgold,
        "mustard": $c-mustard,
        "orange": $c-orange,
        "red": $c-red,
        "garnet": $c-garnet,
        "gray": $c-gray,
        "charcoalgray": $c-charcoalgray,
);

@each $mainColor,
$i in $mainColors {
        .tx-#{$mainColor} {
                color: $i;
        }

        .bgc-#{$mainColor} {
                background-color: $i;
        }

        .bgs-#{$mainColor} {
                border-color: $i !important;
                background-color: $i;
                color: #fff !important;
        }
}

$mainColorsDark: (
        "blue": $c-blue-dark,
        "magenta": $c-magenta-dark,
        "rose": $c-rose-dark,
        "lilac": $c-lilac-dark,
        "purple": $c-purple-dark,
        "eggplant": $c-eggplant-dark,
        "electricblue": $c-electricblue-dark,
        "airforce": $c-airforce-dark,
        "skyblue": $c-skyblue-dark,
        "bluishgreen": $c-bluishgreen-dark,
        "turquoise": $c-turquoise-dark,
        "greenforest": $c-greenforest-dark,
        "green": $c-green-dark,
        "limegreen": $c-limegreen-dark,
        "oldgold": $c-oldgold-dark,
        "mustard": $c-mustard-dark,
        "orange": $c-orange-dark,
        "red": $c-red-dark,
        "garnet": $c-garnet-dark,
        "gray": $c-gray-dark,
        "charcoalgray": $c-charcoalgray-dark,
);

@each $mainColorDark,
$i in $mainColorsDark {

        .bgs-#{$mainColorDark} {
                &.in-seat {
                        border-color: #{$i} !important;
                        background-color: $i;
                }
        }
}

// TX COLOR
.tx-sucs-dark { color: $c-sucs-dark; }
.tx-pend { color: $c-pend; }
.tx-redlight { color: $c-redlight; }
.tx-greenlight { color: $c-greenlight; }