/*-------------------------
    Premieres Mediaset
--------------------------*/

/*----------------------
        RESETS
------------------------*/

*::-webkit-scrollbar {
        width: 4px;
}

*::-webkit-scrollbar-track {
        background: #f2f4f7;
}

*::-webkit-scrollbar-thumb {
        background-color: $c-ter-500;
        border-radius: 20px;
        border: 0px none #f2f4f7;
}

/* fix scrollbars */
@supports (not selector(::-webkit-scrollbar)) {
        html {
                scrollbar-width: thin;
                scrollbar-color: $c-ter-500 #f2f4f7;
        }
}


//main overlays
.overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        z-index: 2;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, .6);

        &.upper {
                z-index: 9998;
        }

        &.full {
                background-color: #000;
        }

        &.white {
                background-color: rgba(255, 255, 255, 0.8);

                &.full {
                        background-color: #fff;
                }
        }


        //Spinner Mediaset
        .mdst-spinner {
                display: inline-block;
                position: relative;
                width: 80px;
                height: 80px;
        }

        .mdst-spinner div {
                animation: mdst-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                transform-origin: 40px 40px;
        }

        .mdst-spinner div:after {
                content: " ";
                display: block;
                position: absolute;
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background: $c-mediaset;
                margin: -4px 0 0 -4px;
        }

        .mdst-spinner div:nth-child(1) {
                animation-delay: -0.036s;
        }

        .mdst-spinner div:nth-child(1):after {
                top: 63px;
                left: 63px;
                background: $c-mtmad;
        }

        .mdst-spinner div:nth-child(2) {
                animation-delay: -0.072s;
        }

        .mdst-spinner div:nth-child(2):after {
                top: 68px;
                left: 56px;
                background: $c-bemad;
        }

        .mdst-spinner div:nth-child(3) {
                animation-delay: -0.108s;
        }

        .mdst-spinner div:nth-child(3):after {
                top: 71px;
                left: 48px;
                background: $c-divinity;
        }

        .mdst-spinner div:nth-child(4) {
                animation-delay: -0.144s;
        }

        .mdst-spinner div:nth-child(4):after {
                top: 72px;
                left: 40px;
                background: $c-energy;
        }

        .mdst-spinner div:nth-child(5) {
                animation-delay: -0.18s;
        }

        .mdst-spinner div:nth-child(5):after {
                top: 71px;
                left: 32px;
                background: $c-boing;
        }

        .mdst-spinner div:nth-child(6) {
                animation-delay: -0.216s;
        }

        .mdst-spinner div:nth-child(6):after {
                top: 68px;
                left: 24px;
                background: $c-fdf;
        }

        .mdst-spinner div:nth-child(7) {
                animation-delay: -0.252s;
        }

        .mdst-spinner div:nth-child(7):after {
                top: 63px;
                left: 17px;
                background: $c-cuatro;
        }

        .mdst-spinner div:nth-child(8) {
                animation-delay: -0.288s;
        }

        .mdst-spinner div:nth-child(8):after {
                top: 56px;
                left: 12px;
                background: $c-telecinco;
        }

        @keyframes mdst-spinner {
                0% {
                        transform: rotate(0deg);
                }

                100% {
                        transform: rotate(360deg);
                }
        }

}

/*----------------------
        ESTRUCTURA
------------------------*/

html,
body {
        font-family: 'Lato';
        font-weight: normal;
        background-color: #f2f4f7;
        font-size: 14px;
        font-weight: $fw-regular;
        color: $c-ter-700;
}

.container {
        max-width: 1045px;
}

.main-container {
        padding: 0 7% 2% 7%;
}

h1 {
        font-size: 28px;
        line-height: 34px;
}

h2 {
        font-size: 24px;
        line-height: 30px;
}

h3 {
        font-size: 20px;
        line-height: 26px;
}

h4 {
        font-size: 15px;
        line-height: 20px;
}

h1,
h2,
h3,
h4 {
        font-weight: 900;
        color: $c-ter-700;

}

.text-main {
        font-size: 16px;
        font-weight: bold;
}

.text-xs {
        font-size: 12px;
        font-weight: 300;
}

.card {
        border-radius: 8px;
        box-shadow: 0 2px 4px 0 rgba(105, 153, 176, 0.12);
        background-color: #fff;
        border: none;
}

.toast {
        border-radius: 8px;
        box-shadow: 0 2px 10px 0 rgba(8, 32, 66, 0.24);
        background-color: #fff;
}

.bg-gray {
        background-color: $c-ter-200;
}

.bg-white {
        background-color: #fff;
}

.mediaset-circle {
        //background-image: url('images/logo-mediaset-circle.svg');
        background-repeat: no-repeat;
        background-size: contain;
        width: 40px;
        height: 40px;
        filter: invert(19%) sepia(12%) saturate(4869%) hue-rotate(187deg) brightness(95%) contrast(93%);
}

.bullet {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: .7rem;
        background-color: #fff;
        border: 1px solid $c-ter-500;
        display: flex;

        &.green {
                background-color: $c-sucs-dark;
                border-color: $c-sucs-dark;
        }
}

.badge-count {
        position: relative;
        top: -3px;

        span {
                font-weight: $fw-heavy;
                font-size: 11px;
                background-color: $c-ter-600;
                color: #fff;
                margin-left: 1rem;
                border-radius: 9px;
                padding: 2px 7px 3px 7px;
        }
}

.boxed-icon {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        box-shadow: 0 2px 4px 0 rgba(105, 153, 176, 0.12);
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 0;
        position: relative;
        background-color: #fff;
        font-size: 16px;

        &.w-badge {

                &::after {
                        position: absolute;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 9px;
                        color: #fff;
                        width: 18px;
                        height: 18px;
                        top: -5px;
                        right: -5px;
                        border-radius: 50%;
                        background-color: $c-red;
                        content: attr(data-count);
                        font-weight: $fw-bold;
                }
        }
}


// NAV HORIZONTAL (Main)

.navbar {

        &.bg-main-bar {
                background-color: #fff;
                height: 64px;
        }

        .navbar-brand {
                padding: 0;
                margin: .2rem 1.5rem 0;
        }

        .logo {
                font-weight: $fw-heavy;
                display: flex;
                align-items: center;

                .logo-full {
                        background: url('./images/logo-full.svg') center center no-repeat;
                        width: 81px;
                        height: 19px;
                }
        }

        &.navbar-light {
                .navbar-nav {
                        .nav-link {
                                font-weight: $fw-heavy;
                                color: $c-ter-700;
                                font-size: 15px;
                                padding: 0 1.5rem;

                                &:hover,
                                &.active {
                                        color: $c-prim;
                                }

                                &:focus-visible {
                                        box-shadow: none;
                                }
                        }
                }
        }

        .dropdown {
                .dropdown-menu {
                        top: 30px !important;
                        right: 15px !important;
                }
        }

}

// NAV PRINCIPAL (vertical) 

.nav-vertical {
        background-color: #fff;
        position: sticky;
        top: 0;
        z-index: 1020;
        padding: 0 7px;

        .nav-container {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                position: sticky;
                top: 0;
                z-index: 1020;

                .nav-icons {
                        text-align: center;
                        align-items: center;
                        justify-content: space-between;
                        flex-wrap: nowrap;
                        margin: 0 auto auto auto;
                        width: 100%;

                        &.nav-pills {

                                .nav-item {
                                        margin-bottom: 2rem;

                                        .nav-link {
                                                border-radius: 4px;
                                                padding: 5px;

                                                i {
                                                        color: $c-ter-700;

                                                        &.fticon-mail {
                                                                font-size: 10px;
                                                                position: relative;
                                                                top: -1px;
                                                                padding-right: 5px;
                                                                padding-left: 2px;
                                                        }
                                                }

                                                &.active,
                                                &:hover {
                                                        background-color: rgba($c-prim, .16);

                                                        i {
                                                                color: $c-prim;
                                                        }
                                                }

                                                &:focus-visible {
                                                        box-shadow: 0 0 0 0.20rem rgba($c-prim-light, .50);
                                                }
                                        }

                                        &.logo {
                                                margin-top: 1rem;
                                                margin-bottom: 3rem;

                                                .nav-link {

                                                        &.active,
                                                        &:hover {
                                                                background-color: transparent;
                                                        }
                                                }

                                                .logo-slim {
                                                        background: url('./images/logo-light.svg') center center no-repeat;
                                                        width: 35px;
                                                        height: 19px;
                                                }
                                        }
                                }
                        }

                }

        }


}

// NAV HORIZONTAL (inPage)

.inpage-nav {

        .nav-item {

                .nav-link {
                        font-weight: $fw-bold;
                        font-size: 14px;
                        color: $c-ter-700;
                        padding: 0.5rem 0;
                        margin: 0 2.5rem 0 0;
                        border-bottom: 2px solid transparent;
                        cursor: pointer;

                        span {
                                font-weight: $fw-heavy;
                                font-size: 11px;
                                background-color: rgba($c-ter-600, .12);
                                color: $c-ter-600;
                                margin-left: .5rem;
                                border-radius: 9px;
                                padding: 2px 7px 3px 7px;
                        }

                        &:hover {
                                border-bottom: 2px solid $c-ter-400;
                        }


                        &.active {
                                color: $c-prim;
                                border-bottom: 2px solid $c-prim;

                                span {
                                        background-color: rgba($c-prim, .12);
                                        color: $c-prim;
                                }
                        }

                        &:focus-visible {
                                box-shadow: none;
                        }


                }
        }

}


/*-------------------------
       COMPONENTES
--------------------------*/

// TOAST

.Toastify {
        .Toastify__toast-container {
                width: 388px;
                z-index: 9999;

                .Toastify__toast {
                        border-radius: 8px;
                        box-shadow: 0 2px 10px 0 rgba(8, 32, 66, 0.24);
                        font-family: 'Lato';
                        font-weight: $fw-bold;
                        color: $c-ter-700;
                        padding: 28px;

                        .Toastify__toast-icon {
                                font-size: 16px;
                                margin-right: 1rem;

                                .fticon-confirm {
                                        color: $c-sucs;
                                }

                                .fticon-state-cancel {
                                        color: $c-error;
                                }

                                .fticon-pending {
                                        color: $c-pend;
                                }
                        }
                }

        }
}

// STICKERS

.sticker {
        padding: 3px 10px 2px;
        border-radius: 4px;
        border: solid 1px;
        font-size: 13px;
        font-weight: $fw-regular;
        display: inline-flex;
        align-items: center;

        i {
                font-size: 9px;
                margin-left: .5rem;
                margin-top: 2px;
                cursor: pointer;
        }

        @each $mainColor,
        $i in $mainColors {
                &.sticker-#{$mainColor} {
                        color: $i;
                        border-color: $i;
                }

                &.sticker-#{$mainColor}-bg {
                        color: #fff;
                        border-color: transparent;
                        background-color: $i;
                }
        }

        &.sticker-st-enviada,
        &.sticker-st-abierta {
                border-color: transparent;
                background-color: rgba($c-greenforest, .16);
                color: $c-greenforest;
        }

        &.sticker-st-borrador {
                border-color: transparent;
                background-color: rgba($c-ter-500, .16);
                color: $c-ter-500;
        }

        &.sticker-st-enviando {
                border-color: transparent;
                background-color: rgba($c-sec, .14);
                color: $c-sec;
        }

        &.sticker-st-incompleta {
                border-color: transparent;
                background-color: rgba($c-orange, .14);
                color: $c-orange;
        }

        &.sticker-st-errorenv,
        &.sticker-st-rebotada {
                border-color: transparent;
                background-color: rgba($c-prim, .14);
                color: $c-prim;
        }

        &.sticker-st-sinabrir,
        &.sticker-st-sinenviar {
                border-color: transparent;
                background-color: rgba($c-sec, .12);
                color: $c-sec;
        }


}

// MEDALLONES

.medal {
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: $fw-700;
        text-transform: uppercase;

        @each $mainColor,
        $i in $mainColors {
                &.medal-#{$mainColor} {
                        color: $i;
                        background-color: rgba($i, .18);
                }
        }

}

// TOOLTIPS
.__react_component_tooltip {
        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-out !important;
        opacity: 0 !important;
        visibility: visible;

        &.type-dark {
                background-color: #fff;
                color: $c-ter-600;
                box-shadow: 0 2px 10px 0 rgba($c-ter-700, 0.24);
                padding: 12px 21px;
                border-radius: 4px;
        }

        &.show {
                opacity: 1 !important;
                visibility: visible;
        }

        &::after {
                // display: none;
                border-top-color: #fff !important;
                border-bottom-color: #fff !important;
                background-color: #fff !important;
        }

}


// BOTONES

.btn {
        border-radius: 20px;
        font-size: 14px;
        font-weight: bold;
        padding: 9px 28px 10px;
        white-space: nowrap;

        &.btn-icon {
                width: 40px;
                height: 40px;
                display: inline-flex;
                padding: 0;
                justify-content: center;
                align-items: center;
                line-height: 1;

                .fticon-filter {
                        position: relative;
                        left: -1px;
                }

                .fticon-download {
                        position: relative;
                        left: -1px;
                }
        }
}

.btn-prim-check {
        background-color: $c-sucs;
        border: 1px solid $c-sucs;
        outline: none;
        box-shadow: none !important;
        color: #fff;

        &:hover,
        &:focus:hover {
                background-color: $c-sucs-dark;
                border-color: $c-sucs-dark;
        }

        &:focus {
                background-color: $c-sucs;
                border-color: $c-sucs;
        }


        &:disabled,
        &.disabled {
                background-color: $c-sucs;
                color: #fff;
                border-color: $c-sucs;
        }

}

.btn-prim-close {
        background-color: $c-ter-500;
        border: 1px solid $c-ter-500;
        outline: none;
        box-shadow: none !important;
        color: #fff;
        margin: 0px 10px 0px;

        &:hover,
        &:focus:hover {
                background-color: $c-ter-600;
                border-color: $c-ter-600;
        }

        &:focus {
                background-color: $c-ter-500;
                border-color: $c-ter-500;
        }


        &:disabled,
        &.disabled {
                background-color: $c-ter-500;
                color: #fff;
                border-color: $c-ter-500;
        }

}


.btn-prim-dark {
        background-color: $c-prim;
        border: 1px solid $c-prim;
        outline: none;
        box-shadow: none !important;
        color: #fff;

        &:hover,
        &:focus:hover {
                background-color: $c-prim-dark;
                border-color: $c-prim-dark;
        }

        &:focus {
                background-color: $c-prim;
                border-color: $c-prim;
        }


        &:disabled,
        &.disabled {
                background-color: $c-prim-light;
                color: #fff;
                border-color: $c-prim-light;
        }

}

.btn-prim-light {
        background-color: transparent !important;
        border: 1px solid $c-prim-dark;
        outline: none;
        box-shadow: none;
        color: $c-prim;

        &:hover {
                border-color: $c-prim-dark;
                color: $c-prim-dark;
                box-shadow: 0px 0px 1px 2px rgba($c-prim, .2) !important;
        }

        &:focus,
        &.active {
                border-color: $c-prim-dark;
                color: $c-prim-dark;
                box-shadow: none;
        }

        &:focus:hover {
                box-shadow: 0px 0px 1px 2px rgba($c-prim, .2) !important;
        }


        &:disabled,
        &.disabled {
                border-color: $c-prim-light;
                color: rgba($c-prim-dark, .6);
        }

}

.btn-prim-link {
        background-color: transparent !important;
        border: none;
        outline: none;
        box-shadow: none !important;
        color: $c-prim;

        &:hover,
        &:focus {
                border-color: $c-prim-dark;
                color: $c-prim-dark;
                text-decoration: underline;
        }

        &:disabled,
        &.disabled {
                border-color: $c-prim-light;
                color: rgba($c-prim-dark, .6);
        }

        &.btn-icon {

                &:hover,
                &:focus {
                        background-color: rgba($c-prim, .2) !important;
                        color: $c-prim-dark;
                        text-decoration: none;
                }
        }

}


.btn-inmap {
        background-color: transparent !important;
        border: none;
        outline: none;
        box-shadow: none !important;
        color: $c-ter-700;
        border-radius: 0px;

        &.btn-map {

                border-radius: 8px;
        }

        &.btn-plus {
                border-bottom-right-radius: 8px;
                border-bottom-left-radius: 0px;
                border-top-left-radius: 0px;
                border-top-right-radius: 8px;
        }

        &:focus {
                color: $c-ter-700;
        }


        &:hover {
                color: $c-sec-dark;
                text-decoration: underline;
        }

        &:disabled,
        &.disabled {
                border-color: $c-sec-light;
                color: rgba($c-sec-dark, .6);
        }

        &.btn-icon {

                &:focus {
                        color: $c-ter-700;
                }

                &:hover,
                &.active {
                        background-color: rgba($c-sec-dark, .16) !important;
                        color: $c-sec-dark;
                        text-decoration: none;
                }
        }
}


.btn-sec-dark {
        background-color: $c-sec;
        border: 1px solid $c-sec;
        outline: none;
        box-shadow: none !important;
        color: #fff;
        min-height: 42px;
        min-width: 90px;

        &:hover,
        &:focus:hover {
                background-color: $c-sec-dark;
                border-color: $c-sec-dark;
        }

        &:focus {
                background-color: $c-sec;
                border-color: $c-sec;
        }

        &:disabled,
        &.disabled {
                background-color: $c-sec-light;
                color: #fff;
                border-color: $c-sec-light;
        }

}

.btn-sec-light {
        background-color: transparent !important;
        border: 1px solid $c-sec;
        outline: none;
        box-shadow: none;
        color: $c-sec;

        &:hover {
                border-color: $c-sec-dark;
                color: $c-sec-dark;
                box-shadow: 0px 0px 1px 2px rgba($c-sec, .2) !important;
        }

        &:focus,
        &.active {
                border-color: $c-sec-dark;
                color: $c-sec-dark;
                box-shadow: none;
        }

        &:focus:hover {
                box-shadow: 0px 0px 1px 2px rgba($c-sec, .2) !important;
        }

        &:disabled,
        &.disabled {
                border-color: $c-sec-light;
                color: rgba($c-sec-dark, .6);
        }

}

.btn-sec-link {
        background-color: transparent !important;
        border: none;
        outline: none;
        box-shadow: none !important;
        color: $c-sec;

        &:hover,
        &:focus {
                color: $c-sec-dark;
                text-decoration: underline;
        }

        &:disabled,
        &.disabled {
                border-color: $c-sec-light;
                color: rgba($c-sec-dark, .6);
        }

        &.btn-icon {

                &:focus {
                        color: $c-sec-dark;
                        text-decoration: none;
                }

                &:hover {
                        background-color: rgba($c-sec-dark, .16) !important;
                        color: $c-sec-dark;
                        text-decoration: none;
                }
        }

}

.btn-plain-blue {
        border-radius: 4px;
        background-color: $c-mds-blue;
        border-color: $c-mds-blue;
        outline: none !important;
        box-shadow: none !important;

        &:hover,
        &:focus {
                background-color: $c-ter-700;
                border-color: $c-ter-700;
        }
}

.btn-plain-gray {
        border-radius: 4px;
        background-color: $c-ter-300;
        border-color: $c-ter-300;
        color: $c-ter-600;
        outline: none !important;
        box-shadow: none !important;
        padding: 4px 14px;
        font-size: 13px;

        &:hover,
        &:focus {
                background-color: $c-ter-400;
                border-color: $c-ter-400;
        }
}

.btn-green {
        background-color: $c-sucs;
        border: 1px solid $c-sucs;
        outline: none;
        box-shadow: none !important;
        color: #fff;

        &:hover,
        &:focus:hover,
        &:focus {
                background-color: $c-sucs-dark;
                border-color: $c-sucs-dark;
        }


        &:disabled,
        &.disabled {
                background-color: $c-ter-500;
                color: #fff;
                border-color: $c-ter-500;
        }

        .fticon-tick {
                font-size: 10px;
                position: relative;
                left: -3px;
                top: 1px;
        }
}

.button-load {
        width: 25px;
        height: 12px;
        background:
                radial-gradient(circle closest-side, #FFFFFF 90%, #0000) 0% 50%,
                radial-gradient(circle closest-side, #FFFFFF 90%, #0000) 50% 50%,
                radial-gradient(circle closest-side, #FFFFFF 90%, #0000) 100% 50%;
        background-size: calc(100%/3) 6px;
        background-repeat: no-repeat;
        animation: d3 1s infinite linear;
        margin: 0 auto;
}

@keyframes d3 {
        20% {
                background-position: 0% 0%, 50% 50%, 100% 50%
        }

        40% {
                background-position: 0% 100%, 50% 0%, 100% 50%
        }

        60% {
                background-position: 0% 50%, 50% 100%, 100% 0%
        }

        80% {
                background-position: 0% 50%, 50% 50%, 100% 100%
        }
}




.buttons-groups {
        display: flex;

        &.btn-group>.btn {
                border-radius: 20px !important;
                flex: 1 1 0px;
        }

        &.squared {
                &.btn-group>.btn {
                        border-radius: 8px !important;
                }
        }

        .btn {
                text-align: left;
                border: 1px solid $c-ter-400;
                color: $c-ter-600;
                outline: none !important;
                box-shadow: none !important;
                padding: 9px 28px 10px 18px;

                &.active {
                        border: 1px solid $c-sec;
                        color: $c-sec;

                        &::before {
                                font-family: "fonticon";
                                content: '\e81f';
                                width: 16px;
                                height: 16px;
                                background-color: $c-sec;
                                border-radius: 50%;
                                position: absolute;
                                top: 12px;
                                right: 15px;
                                color: #fff;
                                font-size: 5px;
                                line-height: 1;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                padding-top: 2px;
                        }
                }

                &:hover {
                        box-shadow: 0px 0px 1px 2px rgba($c-sec, .2) !important;
                }

                &:disabled:is(.active) {
                        opacity: 1;
                        background-color: transparent;
                }

                &:disabled:not(.active) {
                        opacity: 0.5;
                        background-color: transparent;
                }
        }

        &.squared {
                .btn {
                        &.active {

                                &::before {
                                        border-radius: 5px;
                                }

                        }
                }

        }



        .btn:first-child {
                margin-right: 1rem;
        }

        .btn:last-child {
                margin-right: 0;
        }

}

// ALERTAS

.alert {
        color: $c-ter-700;
        border-radius: 8px;
        padding: 27px 42px 25px;
        border: none;
        font-size: 14px;
        font-weight: $fw-regular;
        color: $c-ter-700;
        display: flex;
        align-items: center;

        i {
                font-size: 24px;
                margin-right: 2rem;
        }

        &.alert-success {
                background-color: rgba($c-sucs-dark, .12);

                i {
                        color: $c-sucs-dark;
                }
        }

        &.alert-info {
                background-color: rgba($c-blue, .12);

                i {
                        color: $c-blue;
                }
        }

        &.alert-error {
                background-color: rgba($c-prim-dark, .12);

                i {
                        color: $c-prim-dark;
                }
        }
}

//swal
.swal2-backdrop-show {
        background: rgba($c-ter-600, .2) !important;
}

.swal2-popup {
        border-radius: 8px;
        box-shadow: 0 2px 10px 0 rgba(8, 32, 66, 0.24);
        color: $c-ter-700 !important;
        width: 40em !important;
        padding: 2rem 3rem 3rem !important;
        border: solid 1px #bfcbd7 !important;

        .swal2-title {
                font-size: 20px;
                font-weight: $fw-heavy;

        }

        .swal2-html-container {
                font-size: 13px;
        }

        .swal2-select {
                background: #e2e9f0;
                border-radius: 3px;
                width: 50%;
                margin-inline: auto;
                border: solid 1px #bfcbd7 !important;
                font-size: 13px;
                outline: none !important;
        }

}

.swal2-actions {
        margin-top: 3rem !important;
}


.swal2-cancel {
        margin-right: 2rem !important;
}

.swal2-confirm {
        color: #fff !important;
}

.swal2-html-container {
        margin: 2em 1.6em 1em;
}

//ddwn 
.dropdown {

        .btn-submenu {
                padding: 0;
                outline: none !important;
                box-shadow: none !important;
                background-color: transparent;
                border: none;
                border-radius: 6px;
                padding: 4px 7px 7px 7px;

                &:hover,
                &:focus,
                &:active,
                &.active {
                        color: $c-prim;
                        background-color: rgba($c-prim, .16);
                }
        }

        .dropdown-menu {
                inset: 5px 0px auto auto !important;
                padding: 10px;
                border-radius: 4px;
                box-shadow: 0 2px 11px 0 rgba(191, 203, 215, 0.35);
                border: solid 1px $c-ter-200;
                background-color: #fff;

        }


        .dropdown-item {
                color: $c-ter-700;
                font-weight: $fw-regular;
                font-size: 13px;
                padding: 12px;
                line-height: 1;
                margin-bottom: .5rem;

                &:hover,
                &:focus,
                &:active,
                &.active {
                        color: $c-ter-700;
                        border-radius: 6px;
                        background-color: rgba($c-sec, .14);
                }

                &:last-child {
                        margin-bottom: 0;
                }
        }
}




// SIDEBAR RIGHT
body.modal-open {
        overflow: hidden;
}

.modal-sidebar {

        .modal.right .modal-dialog {
                position: fixed;
                margin: auto;
                width: 384px;
                height: 100%;
                -webkit-transform: translate3d(0%, 0, 0);
                -ms-transform: translate3d(0%, 0, 0);
                -o-transform: translate3d(0%, 0, 0);
                transform: translate3d(0%, 0, 0);
        }

        .modal.right.size-xl .modal-dialog {
                width: 1032px;
        }

        .modal.right.size-xm .modal-dialog {
                width: 680px;
        }

        .modal.right.size-xxl .modal-dialog {
                width: 100vw;
        }

        .modal.right .modal-content {
                height: 100%;
                overflow: hidden;
                border: none;
                border-radius: 0;
        }

        .modal.right .modal-body {
                padding: 30px;
                overflow-y: auto;
                margin-bottom: 50px;
                height: calc(100vh - 128px);
        }

        .modal.right.fade .modal-dialog {
                right: -384px;
                -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
                -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
                -o-transition: opacity 0.3s linear, right 0.3s ease-out;
                transition: opacity 0.3s linear, right 0.3s ease-out;
        }

        .modal.right.fade.size-xl .modal-dialog {
                max-width: inherit;
                right: -1032px;
        }

        .modal.right.fade.size-xm .modal-dialog {
                max-width: inherit;
                right: -680px;
        }

        .modal.right.fade.size-xxl .modal-dialog {
                max-width: inherit;
                right: -100vw;
                -webkit-transition: opacity .5s linear, right .7s ease-out;
                -moz-transition: opacity .5s linear, right .7s ease-out;
                -o-transition: opacity .5s linear, right .7s ease-out;
                transition: opacity .5s linear, right .7s ease-out;
        }

        .modal.right.fade.show .modal-dialog {
                right: 0;
        }

        .modal-header {
                padding: 0;
                height: 64px;
                color: $c-ter-700;
                font-weight: $fw-heavy;
                font-size: 20px;
                border-bottom: 2px solid $c-ter-200;

                div {
                        display: inline-flex;
                        align-items: center;
                }

                .title {
                        padding: 0 1.5rem;
                        width: calc(100% - 68px);
                        white-space: nowrap;
                        overflow: hidden;

                }

                .close {
                        width: 64px;
                        height: 64px;
                        border-left: 2px solid $c-ter-200;
                        justify-content: center;
                        font-size: 12px;
                        cursor: pointer;
                }

                .step-back {
                        width: 64px;
                        height: 64px;
                        border-right: 2px solid $c-ter-200;
                        justify-content: center;
                        font-size: 26px;
                        cursor: pointer;
                        padding: 0px 33px;

                        &.step-disabled {
                                cursor: default;
                                opacity: .3;
                        }
                }

                .action-buttons {
                        .btn {
                                margin-right: 1rem;
                        }
                }
        }

        .modal-footer {
                border-radius: 0;
                background-color: $c-ter-200;
                border: none;
                padding: .5rem;
                position: fixed;
                width: 100%;
                right: 0;
                bottom: 0;
        }

        .modal-backdrop.show {
                opacity: .2;
        }

        &.modal-fullwidth {

                .modal.right {
                        .modal-body {
                                height: calc(100vh - 65px);
                                background-color: $c-ter-200;
                        }

                }

        }

}

.modal-bottom {

        .modal.bottom .modal-dialog {
                position: fixed;
                margin: auto;
                width: 100%;
                max-width: 100% !important;
                height: 164px;
                -webkit-transform: translate3d(0%, 0, 0);
                -ms-transform: translate3d(0%, 0, 0);
                -o-transform: translate3d(0%, 0, 0);
                transform: translate3d(0%, 0, 0);
        }

        .modal.bottom .modal-content {
                height: 100%;
                overflow: hidden;
                border: none;
                border-radius: 0;
        }

        .modal.bottom .modal-body {
                padding: 30px;
                overflow-y: auto;
        }

        .modal.bottom.fade .modal-dialog {
                bottom: -164px;
                -webkit-transition: opacity 0.3s linear, bottom 0.3s ease-out;
                -moz-transition: opacity 0.3s linear, bottom 0.3s ease-out;
                -o-transition: opacity 0.3s linear, bottom 0.3s ease-out;
                transition: opacity 0.3s linear, bottom 0.3s ease-out;
        }


        .modal.bottom.fade.show .modal-dialog {
                bottom: 0;
        }

        .modal-header {
                padding: 0;
                height: 64px;
                color: $c-ter-700;
                font-weight: $fw-heavy;
                font-size: 20px;
                border-bottom: 2px solid $c-ter-200;

                div {
                        display: inline-flex;
                        align-items: center;
                }

                .title {
                        padding: 0 1.5rem;
                        width: calc(100% - 68px);
                        white-space: nowrap;
                        overflow: hidden;

                }

                .action-buttons {
                        .btn {
                                margin-right: 1rem;
                        }
                }
        }


        .modal-backdrop.show {
                opacity: 0;
        }

}

.overlay-bottom-map {
        position: fixed;
        margin: auto;
        width: 100%;
        max-width: 100% !important;
        height: 164px;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
        background-color: #fff;
        bottom: -164px;
        left: 0;
        -webkit-transition: opacity 0.3s linear, bottom 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, bottom 0.3s ease-out;
        -o-transition: opacity 0.3s linear, bottom 0.3s ease-out;
        transition: opacity 0.3s linear, bottom 0.3s ease-out;

        &.show {
                bottom: 0;
        }

        .modal-header {
                padding: 0;
                height: 64px;
                color: $c-ter-700;
                font-weight: $fw-heavy;
                font-size: 20px;
                border-bottom: 2px solid $c-ter-200;

                div {
                        display: inline-flex;
                        align-items: center;
                }

                .title {
                        padding: 0 1.5rem;
                        width: calc(100% - 68px);
                        white-space: nowrap;
                        overflow: hidden;

                }

                .action-buttons {
                        .btn {
                                margin-right: 1rem;
                        }
                }
        }

        .area-edit-form {

                .custom-inputs {
                        display: flex;
                        align-items: center;

                        .mb-3,
                        label,
                        .form-label {
                                margin-bottom: 0 !important;
                        }

                        .box-input {
                                background-color: $c-ter-200;
                                border-radius: 8px;
                                color: $c-ter-700;
                                font-weight: bold;
                                padding: 8px 13px;
                                display: flex;
                                align-items: center;
                                width: fit-content;
                                margin-right: 1.3rem;

                                i {
                                        font-size: 20px;
                                        margin-right: 8px;
                                }

                        }

                        .order-inputs {
                                display: flex;

                                .btn {
                                        margin-right: 1.3rem;
                                        border: 1px solid $c-ter-400;
                                        background-color: $c-ter-200 !important;
                                        color: $c-ter-700;

                                        &.active {
                                                border: solid 1px $c-sec-dark;
                                                background-color: rgba($c-sec-dark, 0.16) !important;
                                        }
                                }

                                i {
                                        font-size: 18px;
                                }

                                &.inputs-disabled {
                                        pointer-events: none;
                                        opacity: .45;

                                        .btn {
                                                &.active {
                                                        border: 1px solid $c-ter-400;
                                                        background-color: $c-ter-200 !important;
                                                }
                                        }
                                }
                        }

                        .rotation-input {
                                .form-control {
                                        width: 100px;
                                }

                        }

                }


        }

}

.overlay-bottom-dashboard {
        position: fixed;
        margin: auto;
        width: calc(100% - 60px);
        height: 160px;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
        background-color: #fff;
        bottom: -160px;
        left: 60px;
        -webkit-transition: opacity 0.3s linear, bottom 0.3s ease-in-out;
        -moz-transition: opacity 0.3s linear, bottom 0.3s ease-in-out;
        -o-transition: opacity 0.3s linear, bottom 0.3s ease-in-out;
        transition: opacity 0.3s linear, bottom 0.3s ease-in-out;

        &.show {
                bottom: 0;
        }

        &.full-w {
                z-index: 2;
                width: 100%;
                left: 0;
        }

        .modal-header {
                padding: 0;
                height: 64px;
                color: $c-ter-700;
                font-weight: $fw-heavy;
                font-size: 20px;
                border-bottom: 2px solid $c-ter-200;

                .close {
                        width: 64px;
                        height: 64px;
                        border-left: 2px solid $c-ter-200;
                        justify-content: center;
                        font-size: 12px;
                        cursor: pointer;
                }

                div {
                        display: inline-flex;
                        align-items: center;
                }

                .title {
                        padding: 0 1.5rem;
                        width: calc(100% - 68px);
                        white-space: nowrap;
                        overflow: hidden;

                        span {
                                margin-left: 1rem;
                                color: $c-sec-dark;
                                font-size: 14px;
                                font-weight: $fw-regular;
                        }

                }

                .action-buttons {
                        .btn {
                                margin-right: 1rem;
                        }
                }
        }

        .modal-body {

                .input-row {
                        margin-bottom: 0;

                        .input-select__control {
                                height: 46px;
                                margin-top: 1px;
                                margin-bottom: 0;
                        }

                        .editing {
                                display: inline-flex;
                                width: calc(100% - 30px);
                                justify-content: space-between;
                                cursor: pointer;

                                div {
                                        &:first-child {
                                                font-weight: $fw-bold;
                                        }
                                }
                        }
                }

                .fields-sitting {
                        display: flex;
                        align-items: center;
                        gap: .5rem;
                }

        }



}

//tables
.table-header {
        margin: 0;
        font-size: 12px;
        font-weight: $fw-bold;
        text-align: left;
        color: $c-ter-700;
        display: flex;
        align-items: center;

        div:first-child {
                display: inline-flex;
                align-items: center;
        }

        .spacer-medal {
                width: 56px;
        }

        .col-star,
        .col-check {

                .vip {

                        i {

                                &.fticon-star-solid {
                                        top: 0;
                                }
                        }

                        .vip-filter {
                                cursor: pointer;
                        }
                }

        }

        .filter-order {
                cursor: pointer;
        }

        .table-filter {
                position: relative;
                user-select: none;

                .tit {
                        padding: 5px;
                        cursor: pointer;
                        height: 28px;

                        i {
                                margin-left: .2rem;
                        }

                        &.w-opt-sel {
                                background-color: rgba($c-sec-light, .35);
                                border-radius: 4px;
                                color: $c-sec-dark;
                                font-size: 9px;

                                i {
                                        font-size: 7.2px;
                                        position: relative;
                                        top: 1px;
                                        right: -1px;
                                }
                        }
                }

                .popup-opts {
                        display: none;
                        position: absolute;
                        top: 30px;
                        transform: translateX(-45%);
                        left: 40%;
                        width: max-content;
                        padding: 1rem 2rem;
                        border-radius: 4px;
                        box-shadow: 0 2px 11px 0 rgba(191, 203, 215, 0.35);
                        border: solid 1px $c-ter-200;
                        background-color: #fff;
                        z-index: 2;

                        &.open {
                                display: block;
                        }

                        ul {
                                margin: 0;
                                padding: 0;
                                list-style: none;

                                li {
                                        margin-bottom: .8rem;
                                        cursor: pointer;

                                        &:hover {
                                                color: $c-ter-600;
                                        }

                                        &:last-child {
                                                margin-bottom: 0;
                                        }
                                }
                        }
                }
        }

}

.table-row {
        border-radius: 6px;
        border: solid 1px $c-ter-300;
        display: flex;
        align-items: center;
        margin: 0 0 .8rem 0;
        padding: 10px 3px;
        cursor: pointer;

        &.no-pointer {
                cursor: default;
        }

        .col-name {
                div {
                        display: inline-flex;
                        align-items: center;
                }

                .name {
                        overflow: hidden;
                        white-space: nowrap;
                        width: calc(100% - 52px);
                }

                .medal {
                        margin-right: 20px;
                }

        }

        .col-actions {
                display: inline-flex;
                align-items: center;
                justify-content: space-between;

                div {
                        display: inline-flex;
                        justify-content: space-between;
                }

                .date {
                        font-size: 12px;
                        color: $c-ter-600;
                        margin-left: 12px;
                }
        }

        .col-icon {
                text-align: center;

                i {
                        margin-right: .5rem;
                }

                span {
                        font-size: 12px;
                        color: $c-ter-600;
                }
        }

        .col-sticker {
                div {
                        width: max-content;
                }
        }

        .sticker {
                margin: 3px;
        }

        &.w-proc {
                min-height: 62px;
        }

        .mail-list {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;


                div {
                        display: inline-flex;

                        &::after {
                                content: ',';
                                margin-right: 0.3rem;
                        }

                        &:last-child {
                                &::after {
                                        content: '';
                                        margin-right: 0;
                                }
                        }
                }
        }

        .mail-edit {
                display: flex;
                align-items: center;

                .drop-button {
                        margin-left: .5rem;
                }

                .drop-content {
                        left: inherit;
                        right: 0;
                }

        }

        .col-opts {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .izq {
                        display: flex;
                }
        }

        .icon-table {
                width: 36px;
                height: 36px;
                border-radius: 6px;
                background-color: $c-ter-200;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $c-ter-600;
                line-height: 0;

                span {
                        font-size: 11px;
                        margin-right: 5px;
                }

                &.disable {
                        opacity: .5;
                }

                &.icon-mail {
                        i {
                                font-size: 11px;
                                position: relative;
                                left: -2px;
                                top: 2px;
                        }

                }

                &.icon-photocall {
                        i {
                                margin-left: -2px;
                        }

                }

                &.icon-sitting {
                        width: 58px;

                        i {
                                margin-right: .5rem;
                        }

                        &.w-seats {
                                color: $c-sec;
                                font-weight: $fw-bold;
                        }

                        &.sentados {
                                color: $c-sec;
                                font-weight: $fw-bold;
                                background-color: rgba($c-sucs-dark, .1);
                        }

                }

                &.icon-asistentes {
                        margin-left: 1rem;
                        padding: 0 30px;
                        margin-right: .5rem;

                        i {
                                margin-right: .5rem;
                        }

                        &.w-comments {
                                position: relative;

                                &::before {
                                        font-family: "fonticon";
                                        content: '\e816';
                                        font-size: 14px;
                                        color: $c-error;
                                        position: absolute;
                                        top: 8px;
                                        left: -4px;
                                        background-color: rgba($c-error, .09);
                                }

                        }

                }

                &.icon-state {
                        background-color: transparent;

                        .state-confirm {
                                color: $c-sucs;
                        }

                        .state-cancel {
                                color: $c-error;
                        }

                        .state-out {
                                color: $c-ter-500;
                        }

                        .pendientes {
                                color: $c-pend;
                        }
                }

                &.icon-entrada {
                        &.st-enviada {
                                color: $c-sucs-dark;
                                background-color: rgba($c-sucs-dark, .16);
                        }

                        &.st-modificada {
                                background-color: rgba($c-error, .14);
                                color: $c-error;
                        }

                        &.st-error {
                                background-color: rgba($c-orange, .14);
                                color: $c-orange-dark;
                        }

                }
        }

}

.col-star {
        display: flex;
        align-items: center;

        .vip {
                width: 30px;

                i {
                        font-size: 11px;


                        &.fticon-star-solid {
                                color: $c-pend;
                                position: relative;
                                top: -2px;
                        }
                }
        }



        .name {
                line-height: 1.2;

                .sub-proc {
                        font-size: 11px;
                        color: $c-ter-600;
                }
        }

}


//acordeones
.accordion {


        .accordion-item {
                border: solid 1px $c-ter-300;

                &:first-of-type {
                        border-top-left-radius: 6px;
                        border-top-right-radius: 6px;

                        .accordion-button {
                                border-top-left-radius: 6px;
                                border-top-right-radius: 6px;
                        }
                }


                &:not(:first-of-type) {
                        border-top: 0;
                }

                .accordion-button {
                        font-size: 14px;
                        font-weight: $fw-bold;
                        color: $c-ter-700;
                        padding: .5rem 1.25rem;

                        .delete-div {
                                position: absolute;
                                right: 40px;
                                cursor: pointer;
                                color: $c-prim;
                        }

                        &:focus {
                                outline: none;
                                box-shadow: none;
                                border-color: $c-ter-300;
                                background-color: #fff;
                        }

                        &:not(.collapsed) {
                                color: $c-ter-700;
                                background-color: #fff;
                                box-shadow: inset 0 -1px 0 $c-ter-300;
                        }

                        &::after {
                                content: '\e82b';
                                font-family: "fonticon";
                                background: none;
                                width: auto;
                                height: auto;
                                font-size: 16px;
                        }

                }

        }
}

//popups-tooltip
.popup-content {
        margin: auto;
        background: #fff;
        width: 50%;
        padding: 15px;
        border: 1px solid #fff;
}

[role=tooltip].popup-content {
        width: 200px;
        box-shadow: 0 2px 10px 0 rgba(8, 32, 66, 0.24);
        border-radius: 4px;
}

.popup-overlay {
        background: rgba(0, 0, 0, .5);
}

[data-popup=tooltip].popup-overlay {
        background: transparent;
}

.popup-arrow {
        -webkit-filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, .16));
        filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, .16));
        color: #fff;
        stroke-width: 2px;
        stroke: #fff;
        stroke-dasharray: 30px;
        stroke-dashoffset: -54px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
}

/*----------------------
     NOTIFICACIONES
------------------------*/

/*----------------------
        MODALES
------------------------*/

/*-------------------------
        PÁGINAS
--------------------------*/

.page-header {
        display: flex;
        align-items: center;

        h1 {
                margin-bottom: 0;
                font-size: 20px;
        }

        .btn {
                min-width: 137px;
        }

}


//home
.home-page {

        .btn,
        .sticker,
        .medal {
                margin-right: 1rem;
        }

}

//contacts
.contacts-page {

        .contact-list {
                .contact-filters {
                        margin-bottom: .5rem;
                }

                .scrollable-contacts {
                        height: calc(100vh - 400px) !important;
                        min-height: 140px;
                        padding: 0 10px 0 0;
                        overflow: hidden;
                        overflow-y: scroll;

                        &.compact {
                                height: inherit !important;
                                overflow-y: auto !important;
                        }
                }
        }

}

.contact-read {
        text-align: center;
        margin: 32px;

        .medal {
                position: relative;
                top: -35px;
                width: 56px;
                height: 56px;
                margin-bottom: -50px;
                font-size: 18px;
        }

        .sticker {
                margin: 3px;
        }

        .contact-details {
                font-size: 13px;
                color: $c-ter-600;
                margin-top: 1.5rem;

                p {
                        margin-bottom: .2rem;
                }
        }

        .contact-companydetails {
                font-size: 13px;
                color: $c-ter-600;
                margin-bottom: 1.5rem;

                p {
                        margin-bottom: .2rem;
                }
        }

        .contact-edit {
                margin-top: 2rem;
                margin-bottom: .5rem;
        }
}

.denied-page {
        min-height: 80vh;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        .ball {
                width: 62px;
                height: 62px;
                background-color: $c-prim;
                border-radius: 50%;
                line-height: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto 2rem auto;

                i {
                        color: #fff;
                        font-size: 28px;
                }

        }
}

//sites
.sites-page {
        .sites-list {
                margin-bottom: 1.5rem;
                cursor: pointer;

                .card-body {
                        padding: 25px;
                }

                .site-name-row {
                        margin-bottom: 33px;
                }

                .info {
                        display: flex;
                        align-items: center;

                        .medal {
                                width: 54px;
                                height: 54px;
                                font-size: 18px;
                                display: inline-flex;
                                margin-right: 20px;

                        }

                        .box {
                                display: inline-flex;
                                flex-direction: column;

                                .nombre {
                                        font-size: 20px;
                                        font-weight: $fw-heavy;
                                        margin-bottom: .2rem;
                                        text-transform: capitalize;
                                }

                                .aforo {
                                        font-size: 13px;
                                        font-weight: $fw-regular;

                                        i {
                                                color: $c-ter-600;


                                        }

                                        span {
                                                &::before {
                                                        content: "";
                                                        margin: 0 3px;
                                                }
                                        }
                                }
                        }



                }
        }
}

.site-read {
        text-align: center;

        .medal {
                position: relative;
                top: -35px;
                width: 56px;
                height: 56px;
                margin-bottom: -50px;
                font-size: 18px;
        }


        .site-details {
                font-size: 13px;
                color: $c-ter-600;

                p {
                        margin-bottom: .2rem;
                }

                span {
                        color: $c-ter-700;
                        margin-left: .5rem;
                        margin-top: .5rem;
                }
        }

        .site-accesos,
        .site-salas {
                font-size: 13px;
                color: $c-ter-600;
                margin-top: 1.5rem;
                text-align: left;

                p {
                        margin-bottom: .2rem;
                }

                h4 {
                        font-size: 14px;
                        color: $c-ter-700;
                        font-weight: $fw-bold;
                        margin-left: 2px;
                        margin-bottom: 0;
                }

                .acceso {
                        margin-top: .5rem;
                        padding: 14px 20px;
                        border-radius: 8px;
                        background-color: $c-ter-200;

                        .tit {
                                font-weight: $fw-700;
                                color: $c-ter-700;
                                text-transform: capitalize;
                        }

                        .dir {
                                color: $c-ter-600
                        }
                }

                .sala {
                        padding: 14px 20px;
                        border-radius: 8px;
                        background-color: $c-ter-200;
                        margin-top: .5rem;
                        border-left: 5px solid;

                        .tit {
                                font-weight: $fw-700;
                                color: $c-ter-700;
                                text-transform: capitalize;

                                span {
                                        color: $c-ter-600;
                                        font-weight: $fw-regular;

                                        &::before {
                                                content: "·";
                                                margin: 0 10px;
                                        }
                                }
                        }

                        .dir {
                                color: $c-ter-600
                        }

                        &:last-child {
                                margin-bottom: 2rem;
                        }
                }

                .salaWithMap {
                        padding: 14px 20px;
                        border-radius: 8px;
                        background-color: $c-ter-200;
                        margin-top: .5rem;
                        // border-left: 5px solid;

                        .tit {
                                font-weight: $fw-700;
                                color: $c-ter-700;
                                text-transform: capitalize;

                                span {
                                        color: $c-ter-600;
                                        font-weight: $fw-regular;

                                        &::before {
                                                content: "·";
                                                margin: 0 10px;
                                        }
                                }
                        }

                        .tit1 {
                                font-weight: $fw-700;
                                color: $c-ter-700;
                                text-transform: capitalize;

                                span {
                                        color: $c-ter-600;
                                        font-weight: $fw-regular;

                                        &::before {
                                                content: "·";
                                                margin: 0 10px;
                                        }
                                }
                        }

                        .tit-zona {
                                color: $c-ter-600;
                                font-weight: $fw-regular;
                                text-transform: capitalize;
                                display: flex;
                                align-items: center;
                                line-height: 1.7;
                                font-size: 11px;

                                .bullet-sala {
                                        width: 10px;
                                        height: 10px;
                                        border-radius: 50%;
                                        margin-right: .7rem;
                                }

                                .nombre-sala {
                                        font-weight: $fw-bold;

                                }

                                .seats-sala {

                                        &::before {
                                                content: "·";
                                                margin: 0 10px;
                                        }

                                }

                                &:first-child {
                                        border-top: 1px solid $c-ter-400;
                                        margin-top: 10px;
                                        padding-top: 10px;
                                }

                        }

                        .dir {
                                color: $c-ter-600;
                        }

                        &:last-child {
                                margin-bottom: 2rem;
                        }

                        .color-zona {
                                width: 10px;
                                height: 10px;
                                margin: 9px 8px 3px 0;

                                &::before {
                                        content: "";
                                        margin: 0 10px;
                                }
                        }
                }

        }
}

.site-button {
        min-width: 154px;
        height: 56px;
        border-radius: 6px;
        border: solid 1px $c-ter-300;
        background-color: #fff;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px 10px 7px;
        margin: 0 10px 10px 0;
        line-height: 1;

        div {
                display: inline-flex;
        }

        .icon {
                i {
                        font-size: 21px;
                        line-height: 0;
                        width: 32px;
                }
        }

        .name {
                font-weight: $fw-bold;
                font-size: 14px;
                color: $c-ter-700;
                text-transform: capitalize;
        }

        .aforo {
                font-weight: $fw-regular;
                font-size: 13px;
                color: $c-ter-600;

                &::before {
                        content: "·";
                        margin: 0 8px;
                        font-weight: $fw-bold;
                }
        }
}

//mapa container
.map-container {
        // width: fit-content;
        // max-width: 100%;
        margin: 0 auto;

        .map-area {
                margin: 0 auto 1rem auto;
                width: fit-content;
                max-width: 100%;


                .area-title {
                        font-size: 20px;
                        font-weight: $fw-heavy;
                        margin-bottom: .5rem;
                        color: $c-ter-700;
                        padding-left: 15px;
                }

                .area-map {
                        border: 1px solid transparent;
                        padding: 10px;
                        overflow-x: auto;

                        .area-row {
                                display: flex;
                                align-items: center;
                                width: fit-content;

                                .row-seat {
                                        width: 16px;
                                        height: 16px;
                                        margin: 3px;
                                        display: inline-flex;
                                        line-height: 0;

                                        &.seat {
                                                border: solid 1px $c-ter-400;
                                                border-radius: 50%;
                                        }

                                        &.space {
                                                border: solid 1px transparent;
                                        }

                                        &.screen {
                                                border: solid 1px $c-ter-700;
                                                background-color: $c-ter-700;
                                                margin-right: 0;
                                                margin-left: 0;
                                                width: 22px;
                                        }

                                        &.text {
                                                border: solid 1px transparent;

                                                span {
                                                        color: $c-ter-600;
                                                        display: flex;
                                                        font-size: 10px;
                                                        justify-content: center;
                                                        align-items: flex-end;
                                                        width: 100%;
                                                }
                                        }
                                }
                        }
                }
        }

        &.edit-map {

                .area-map {
                        border-color: $c-error;

                        .area-row {

                                .row-seat {
                                        &.seat {
                                                cursor: pointer;

                                                &:hover {
                                                        background-color: $c-ter-400;
                                                }
                                        }

                                        &.space {
                                                cursor: pointer;
                                                background-color: $c-ter-400;

                                                &:hover {
                                                        background-color: $c-ter-500;
                                                }
                                        }

                                        &.text {
                                                cursor: pointer;
                                                border-bottom-color: $c-ter-600;

                                                &:hover {
                                                        background-color: $c-ter-400;
                                                }
                                        }

                                        &.screen {
                                                &:hover {
                                                        border-color: $c-sec-dark;
                                                        background-color: $c-sec-dark;
                                                }
                                        }
                                }
                        }
                }
        }
}

//eventos

.events-page {

        .event-filters {
                margin-bottom: .5rem;
        }


        .event-item {
                display: inline-flex;
                cursor: pointer;
                width: 100%;
                background-color: transparent;
                border-radius: 16px;

                .header-image {
                        height: 270px;
                        background-color: $c-ter-300;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-top-left-radius: 16px;
                        border-top-right-radius: 16px;
                        border-bottom: 1px solid $c-ter-300;

                        img {
                                object-fit: cover;
                                flex: 1;
                                height: 100%;
                                border-top-right-radius: 16px;
                                border-top-left-radius: 16px;
                        }

                        i {
                                color: $c-ter-400;
                                font-size: 50px;
                        }
                }

                .card-body {
                        background-color: #fff;
                        border-bottom-left-radius: 16px;
                        border-bottom-right-radius: 16px;
                }

                .event-info {
                        text-align: center;

                        div {
                                margin-bottom: .2rem;
                        }

                        .event-name {
                                font-weight: $fw-heavy;
                                font-size: 15px;
                        }

                        .event-date {
                                font-size: 12px;

                                span {
                                        font-size: 12px;

                                        &::before {
                                                content: "·";
                                                margin: 0 5px;
                                        }
                                }
                        }

                        .event-site {
                                font-size: 12px;
                        }
                }

                .event-past {
                        border-top: 1px solid $c-ter-400;
                        padding: 1rem 1rem 0;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        margin-top: 1rem;

                        div {
                                display: inline-flex;
                                justify-content: space-between;
                        }

                        .asist {
                                font-size: 12px;
                                color: $c-ter-600;
                        }

                        .asist-data {
                                font-size: 14px;
                                font-weight: $fw-heavy;
                        }
                }
        }

        .event-item-inactive {
                display: inline-flex;
                width: 100%;

                .header-image {
                        height: 270px;
                        background-color: $c-ter-300;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;
                        border-bottom: 1px solid $c-ter-300;
                        background-image: linear-gradient(to bottom, #fff, #fff);
                        opacity: 0.45;
                        mix-blend-mode: luminosity;
                        cursor: pointer;

                        img {
                                object-fit: cover;
                                flex: 1;
                                height: 100%;
                        }

                        i {
                                color: $c-ter-400;
                                font-size: 50px;
                        }
                }

                .event-info {
                        text-align: center;

                        div {
                                margin-bottom: .2rem;
                        }

                        .event-name {
                                opacity: 0.45;
                                font-size: 15px;
                                font-weight: $fw-bold;
                                text-align: center;
                                color: $c-ter-700;
                        }

                        .event-site {
                                opacity: 0.45;
                                font-size: 12px;
                                text-align: center;
                                color: $c-ter-700;
                        }
                }

                .activate-event {
                        width: 100%;
                        height: 20px;
                        margin: 5px 15px 0 1px;
                        display: flex;

                        .activar-evento {
                                width: 80%;
                                height: 16px;
                                font-size: 13px;
                                font-weight: $fw-bold;
                                text-align: left;
                                color: $c-ter-700;
                                display: inline;
                        }

                        .Rectngulo-93 {
                                width: 32px;
                                height: 20px;
                                padding: 2px 14px 2px 2px;
                                border-radius: 10px;
                                background-color: #bfcbd7;
                                display: inline;
                                cursor: pointer;
                        }

                        .check-activate {
                                width: 16px;
                                height: 16px;
                                background-color: #fff;
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: contain;
                                -webkit-appearance: none;
                                appearance: none;
                                border-radius: 100%;
                        }
                }
        }

}

//eventos form

.event-form {
        .step-0 {
                .options {
                        margin: 0 auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .card {
                                display: inline-flex;
                                width: 320px;
                                height: 320px;
                                margin: 2rem 1rem;
                                cursor: pointer;


                                .card-body {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        flex-direction: column;
                                        font-size: 18px;
                                        font-weight: $fw-heavy;

                                        img {
                                                margin-bottom: 2rem;
                                        }
                                }
                        }
                }
        }

        .step-1 {
                max-width: 800px;
                margin: 0 auto;

                .card {
                        border-radius: 16px;

                        .card-body {
                                padding: 40px 30px;

                                h4 {
                                        margin-top: 1.2rem;
                                        margin-bottom: 1rem;
                                }

                                .check {
                                        font-size: 15px;
                                        font-weight: $fw-heavy;

                                        .mb-3 {
                                                margin-bottom: 0 !important;
                                        }
                                }

                                textarea {
                                        height: 90px;
                                }
                        }
                }
        }

        .step-2 {

                .invitados-tit {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 1.5rem;
                }

                .full-box {
                        display: flex;
                        height: 70vh;


                        &.empty {

                                .card-body {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                }
                        }

                        .no-results {
                                text-align: center;

                                p,
                                h3 {
                                        margin-bottom: 1.2rem;
                                        color: $c-ter-600;
                                }
                        }
                }

        }
}

.event-contacts-modal {

        .table-header {
                margin-top: 30px;
                align-items: center;

                .input-type-checkbox {
                        display: block;
                        margin-left: 2px;
                }

                .margin-check {
                        margin-left: .3rem;
                }
        }

        .contact-list {

                .scrollable-contacts {
                        height: calc(100vh - 300px) !important;
                        min-height: 100px;
                        padding: 0 10px 0 0;
                        overflow: hidden;
                        overflow-y: auto;

                        &.compact {
                                height: inherit !important;
                                overflow-y: auto !important;
                        }

                        .table-row {
                                cursor: default;
                        }
                }

                .icon-mail {
                        margin-left: 15px;

                        &.invi-sent {
                                color: $c-sucs;
                        }
                }

                .icon-entrada {
                        margin-left: 15px;
                }

        }

}

.invitados-list {
        .contact-list {
                height: 100% !important;
                overflow: hidden;

                .scrollable-contacts {
                        height: calc(70vh - 140px);
                        min-height: 100px;
                        padding: 0 10px 0 0;
                        overflow: hidden;
                        overflow-y: auto;

                        &.compact {
                                height: inherit !important;
                                overflow-y: auto !important;
                        }

                        .table-row {

                                .fticon-close {
                                        font-size: 9px;
                                }
                        }
                }
        }
}

//dashboard
.dashboard-page {

        .header-dash {
                background-color: #fff;
                margin-bottom: 2rem;
                display: flex;
                align-items: center;
                padding: 1rem 15px;

                h3 {
                        margin-bottom: 0;
                }
        }

        .header-asist {

                .filter-scroll {
                        width: 100%;
                        overflow: hidden;
                        overflow-x: auto;
                        display: flex;
                        justify-content: space-between;

                        .filter-box {
                                min-width: 190px;
                                width: max-content;
                                margin-right: 1rem;
                                margin-bottom: .5rem;
                                flex-grow: 1;

                                .card {
                                        border: 1px solid transparent;
                                }

                                &:last-child {
                                        margin-right: 0;
                                }
                        }

                        &.filtered {
                                .card {
                                        &.filter-active {
                                                border: 1px solid $c-ter-400;
                                        }
                                }

                        }
                }

                .asist-box {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        user-select: none;

                        &.no-pointer {
                                cursor: default;
                        }

                        .icon {
                                border-radius: 6px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-right: 1rem;
                                width: 43px;
                                height: 43px;
                        }

                        .info {
                                .tit {
                                        font-weight: $fw-bold;
                                        font-size: 14px;
                                        color: $c-ter-700;
                                }

                                .nums {
                                        display: inline-flex;
                                        align-items: center;
                                        font-size: 12px;

                                        span {
                                                color: $c-ter-600;

                                                &:first-child {
                                                        font-weight: $fw-bold;

                                                        &::after {
                                                                content: "·";
                                                                margin: 0 10px;
                                                        }
                                                }
                                        }
                                }
                        }

                        &.invitados {
                                .icon {
                                        background-color: rgba($c-sec, .12);
                                        color: $c-sec;
                                }

                                .info {
                                        .nums {
                                                span {
                                                        &:first-child {
                                                                color: $c-sec;
                                                        }
                                                }
                                        }
                                }
                        }

                        &.confirmados {
                                .icon {
                                        background-color: rgba($c-sucs, .2);
                                        color: $c-sucs;
                                }

                                .info {
                                        .nums {
                                                span {
                                                        &:first-child {
                                                                color: $c-sucs;
                                                        }
                                                }
                                        }
                                }
                        }


                        &.fueraplazo {
                                .icon {
                                        background-color: rgba($c-ter-500, .2);
                                        color: $c-ter-500;
                                }

                                .info {
                                        .nums {
                                                span {
                                                        &:first-child {
                                                                color: $c-ter-500;
                                                        }
                                                }
                                        }
                                }
                        }

                        &.noasisten {
                                .icon {
                                        background-color: rgba($c-error, .09);
                                        color: $c-error;
                                }

                                .info {
                                        .nums {
                                                span {
                                                        &:first-child {
                                                                color: $c-error;
                                                        }
                                                }
                                        }
                                }
                        }

                        &.pendientes {
                                .icon {
                                        background-color: rgba($c-pend, .2);
                                        color: $c-pend;
                                }

                                .info {
                                        .nums {
                                                span {
                                                        &:first-child {
                                                                color: $c-pend;
                                                        }
                                                }
                                        }
                                }
                        }

                        &.pend-envio {
                                .icon {
                                        background-color: rgba($c-magenta, .09);
                                        color: $c-magenta;
                                        font-size: 16px;
                                }

                                .info {
                                        .nums {
                                                span {
                                                        &:first-child {
                                                                color: $c-magenta;
                                                        }
                                                }
                                        }
                                }
                        }

                }

        }

        &.dashboard-home {

                h3 {
                        margin-bottom: 1.5rem;
                }

                .grid-50 {
                        width: 45%;

                        &.first-col {
                                margin-right: 10%;
                        }
                }


                .big-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;

                        .card-body {
                                padding: 2rem 1rem;

                                .fticon-user {
                                        font-size: 50px;
                                        line-height: 0;
                                }
                        }

                        .invitaciones {
                                margin: 1rem auto 0 auto;

                                span {
                                        &:first-child {
                                                font-size: 20px;
                                                font-weight: 800;
                                        }

                                        &.pend {
                                                color: $c-redlight;
                                        }

                                        &.sent {
                                                color: $c-greenlight;
                                        }
                                }
                        }

                        .numbers {
                                font-size: 14px;
                                font-weight: $fw-bold;
                                color: $c-ter-500;
                                margin: 1rem auto 0 auto;

                                span {
                                        &:first-child {
                                                font-size: 20px;
                                                font-weight: 800;
                                        }

                                        &:last-child {
                                                &::before {
                                                        content: "/";
                                                        margin: 0 5px;
                                                }
                                        }

                                        &.pend {
                                                color: $c-redlight;
                                        }

                                        &.sent {
                                                color: $c-greenlight;
                                        }
                                }
                        }
                }

                .progress-event {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .ball {
                                width: 56px;
                                height: 56px;
                                display: inline-flex;
                                margin: 0 1.5rem;

                                i {
                                        color: $c-ter-600;

                                        &.fticon-mail {
                                                font-size: 11px;
                                                position: relative;
                                                left: -2px;
                                                top: 1px;
                                        }

                                        &.fticon-ticket {
                                                font-size: 16px;
                                                position: relative;
                                                left: -1px;
                                        }
                                }

                                .invitados .CircularProgressbar-path {
                                        stroke: $c-purplelight;
                                }

                                .edition .CircularProgressbar-path {
                                        stroke: $c-purple;
                                }

                                .invitaciones .CircularProgressbar-path {
                                        stroke: $c-redlight;
                                }

                                .seats .CircularProgressbar-path {
                                        stroke: $c-orange;
                                }

                                .tickets .CircularProgressbar-path {
                                        stroke: $c-pend;
                                }



                                &:first-child {
                                        margin-left: 0;
                                }

                                &:last-child {
                                        margin-right: 0;
                                }
                        }
                }

                .event-header {
                        .card-body {
                                padding: 0;
                        }

                        .header-cont {
                                display: inline-flex;
                        }


                        .event-image {
                                background-repeat: no-repeat;
                                background-size: cover;
                                background-position: top center;
                                min-height: 360px;
                                width: 320px;
                                border-top-left-radius: 8px;
                                border-bottom-left-radius: 8px;

                                .counter-container {
                                        border-top-left-radius: 8px;
                                        border-bottom-left-radius: 8px;
                                        display: flex;
                                        align-items: end;
                                        justify-content: center;
                                        width: 100%;
                                        height: 100%;
                                        background: rgb(255, 255, 255);
                                        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5998774509803921) 60%, rgba(0, 0, 0, 1) 100%);

                                        .event-counter {
                                                display: flex;
                                                padding: 10px 20px;
                                                color: #fff;
                                                width: fit-content;
                                                margin-bottom: 1rem;

                                                div {
                                                        text-align: center;
                                                        margin-right: 2rem;
                                                        line-height: 1.1;

                                                        &:last-child {
                                                                margin-right: 0;
                                                        }

                                                        span {
                                                                display: block;

                                                                &.num {
                                                                        font-weight: 800;
                                                                        font-size: 24px;
                                                                        width: 40px;
                                                                }

                                                                &.text {
                                                                        font-size: 12;
                                                                }
                                                        }
                                                }
                                        }

                                        .event-finished {
                                                display: flex;
                                                align-items: center;
                                                justify-content: space-between;
                                                width: 100%;
                                                color: #fff;
                                                padding: 1rem 2rem;

                                                .asistencia {
                                                        display: flex;
                                                        flex-direction: column;

                                                        .tit {
                                                                line-height: 0.9;
                                                                opacity: .6;
                                                        }

                                                        .data {
                                                                color: rgba(#fff, .6);

                                                                span {
                                                                        font-weight: $fw-heavy;
                                                                        font-size: 20px;
                                                                        color: #fff;
                                                                }
                                                        }
                                                }

                                                .porcentaje {
                                                        font-weight: $fw-bold;
                                                        font-size: 18px;
                                                        line-height: 0;
                                                }
                                        }
                                }
                        }

                        .event-info {
                                padding: 1rem;
                                width: calc(100% - 320px);
                                display: flex;
                                flex-direction: column;
                                justify-content: center;

                                .actions {
                                        text-align: right;
                                        margin-right: .8rem;
                                }

                                .finished {
                                        text-align: right;
                                        margin-right: .8rem;
                                }

                                .general {
                                        margin: 1rem auto 0 auto;
                                        text-align: center;

                                        h1 {
                                                margin-bottom: 1rem;
                                        }

                                        .fecha {
                                                margin-bottom: 7px;
                                                font-size: 15px;
                                                font-weight: $fw-700;

                                                span {
                                                        margin: 0 6px;
                                                }

                                                i {
                                                        margin-right: 6px;
                                                }
                                        }

                                        .lugar {
                                                margin-bottom: 1rem;
                                                font-weight: $fw-700;
                                                font-size: 14px;

                                                span {
                                                        margin: 0 6px;
                                                }
                                        }

                                        .invitados {
                                                border-radius: 6px;
                                                padding: 10px;
                                                background-color: $c-ter-200;
                                                color: $c-ter-500;
                                                width: fit-content;
                                                margin: 0 auto;

                                                .blue {
                                                        color: $c-blue;
                                                        margin-right: .4rem;
                                                        font-weight: $fw-bold;

                                                        i {
                                                                margin-right: .3rem;
                                                        }
                                                }
                                        }

                                        .descripcion {
                                                p {
                                                        margin-top: .8rem;
                                                }
                                        }

                                        .no-desc {
                                                margin-top: 1.5rem;
                                        }
                                }

                                .limite {
                                        margin: 1.5rem auto 1rem;

                                        p {
                                                margin-bottom: 0;
                                                text-align: center;
                                                font-weight: $fw-700;
                                                font-size: 14px;

                                                span {
                                                        margin: 0 6px;
                                                }
                                        }
                                }
                        }
                }

                .asistentes {
                        display: flex;
                        align-items: center;

                        .status {
                                padding: .6rem;
                                font-size: 18px;
                                border-radius: 6px;
                                width: fit-content;
                                display: inline-flex;
                                margin-right: 1rem;

                                &.confirmados {
                                        color: $c-sucs;
                                        background-color: rgba($c-sucs, .2);
                                }

                                &.invitados {
                                        background-color: rgba($c-sec, .12);
                                        color: $c-sec;
                                }

                                &.fueraplazo {
                                        color: $c-ter-500;
                                        background-color: rgba($c-ter-500, .2);
                                }

                                &.noasisten {
                                        color: $c-error;
                                        background-color: rgba($c-error, .09);
                                }

                                &.pendientes {
                                        color: $c-pend;
                                        background-color: rgba($c-pend, .2);
                                }

                                &.pend-envio {
                                        background-color: rgba($c-magenta, .09);
                                        color: $c-magenta;
                                }

                        }

                        .info {

                                .text {
                                        font-size: 14px;
                                        font-weight: $fw-bold;
                                }

                                .datos {
                                        font-size: 12px;
                                        color: $c-ter-600;

                                        span {
                                                &:first-child {
                                                        font-weight: $fw-bold;

                                                        &::after {
                                                                content: "·";
                                                                margin: 0 5px;
                                                        }
                                                }
                                        }
                                }

                                &.confirmado {
                                        .datos span:first-child {
                                                color: $c-sucs;
                                        }
                                }

                                &.fueradeplazo {
                                        .datos span:first-child {
                                                color: $c-ter-500;
                                        }
                                }

                                &.noasisten {
                                        .datos span:first-child {
                                                color: $c-error;
                                        }
                                }

                                &.pendientes {
                                        .datos span:first-child {
                                                color: $c-pend;
                                        }
                                }
                        }
                }

                .status-envios {

                        .fticon-close {
                                color: $c-prim-dark;
                                font-size: 11px;
                        }

                        .fticon-mail-open {
                                color: $c-sucs-dark;
                                font-size: 13px;
                        }

                        .fticon-mail {
                                color: $c-sec;
                                font-size: 11px;
                        }

                        .info {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                margin-top: .5rem;

                                span {
                                        &:first-child {
                                                font-size: 13px;
                                                color: $c-ter-600;
                                        }

                                        &:last-child {
                                                font-size: 14px;
                                                font-weight: $fw-bold;
                                                color: $c-ter-700;
                                                margin-right: 2px;
                                        }
                                }
                        }
                }

                .w-bullet {
                        &::before {
                                content: '';
                                display: inline-block;
                                margin-right: 5px;
                                width: 8px;
                                height: 8px;
                                border-radius: 50%;
                                border: 1px solid $c-ter-500;
                                position: relative;
                                top: -1px;
                        }
                }

                .w-aforo {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 1rem;
                        margin-bottom: 1.5rem;

                        h3 {
                                margin-bottom: 0;
                        }

                        .info {
                                .w-bullet {
                                        margin-right: 1rem;
                                }

                                span {
                                        &:nth-child(2) {
                                                font-weight: $fw-bold;
                                        }

                                        &:last-child {
                                                color: $c-ter-500;
                                                margin-right: 2px;
                                        }
                                }
                        }
                }

                .w-icon {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 1rem;
                        margin-bottom: 1.5rem;

                        h3 {
                                margin-bottom: 0;
                        }
                }

                .sala-event {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .info {
                                display: inline-flex;
                                align-items: center;

                                i {
                                        font-size: 24px;
                                        margin-right: .3rem;
                                        line-height: 1;
                                }

                                span {
                                        font-size: 16px;
                                        font-weight: $fw-bold;
                                }
                        }

                        .aforo {
                                margin-right: 5px;
                                display: inline-flex;

                                div {
                                        display: flex;
                                        flex-direction: column;
                                        text-align: center;

                                        .fticon-user {
                                                margin-right: .3rem;
                                        }

                                        span {

                                                &.disp {
                                                        font-weight: $fw-bold;
                                                }

                                                &.tot {
                                                        font-weight: $fw-bold;
                                                        color: $c-ter-500;
                                                }
                                        }

                                        &.tit {
                                                display: flex;
                                                justify-content: center;
                                                align-items: flex-start;
                                                flex-direction: row;
                                        }

                                        &.data {
                                                display: inline-flex;
                                                align-items: center;
                                                flex-direction: row;
                                                justify-content: center;
                                        }
                                }


                                .ocupado {
                                        span {
                                                &.tit {
                                                        &::before {
                                                                border-color: $c-greenlight;
                                                                background-color: $c-greenlight;
                                                        }
                                                }
                                        }
                                }

                                .toenter {
                                        span {
                                                &.disp {
                                                        color: $c-redlight;
                                                }
                                        }
                                }

                                .inside {
                                        span {
                                                &.disp {
                                                        color: $c-greenlight;
                                                }
                                        }
                                }
                        }
                }

                .qrs-gen {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .info {
                                display: inline-flex;
                                align-items: center;

                                i {
                                        font-size: 40px;
                                        margin-right: 1rem;
                                        line-height: 1;
                                }

                                span {
                                        font-size: 16px;
                                        font-weight: $fw-bold;
                                }
                        }

                        .cantidad {
                                span {
                                        font-size: 24px;
                                        font-weight: $fw-heavy;
                                        margin-right: .7rem;
                                }
                        }
                }
        }

        &.dashboard-invitados {

                .full-box {
                        display: flex;
                        height: 70vh;


                        &.empty {

                                .card-body {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                }
                        }

                        &.md-btm-is-open {
                                margin-bottom: 160px;
                        }

                        .no-results {
                                text-align: center;

                                p,
                                h3 {
                                        margin-bottom: 1.2rem;
                                        color: $c-ter-600;
                                }
                        }
                }


                .invitados-list {

                        .table-header {
                                margin: 0 -15px;

                                .check {
                                        margin-left: 4px;
                                }

                                .col-invitacion {
                                        margin: 0 -10px;
                                }

                                .col-photocall {
                                        margin: 0 3px;
                                }

                                .col-asistentes {
                                        margin: 0 0px 0 10px;
                                }

                                .col-confirmacion {
                                        margin: 0;
                                        position: relative;
                                        left: -20px;
                                }

                                .col-check {
                                        margin-left: 15px;
                                }
                        }

                        .col-check {
                                display: flex;
                                align-items: center;

                                .check {
                                        margin-right: 1rem;
                                        width: 20px;
                                }

                                .vip {
                                        width: 30px;

                                        i {
                                                font-size: 11px;

                                                &.fticon-star-solid {
                                                        color: $c-pend;
                                                        position: relative;
                                                        top: -2px;
                                                }
                                        }
                                }

                                .name {
                                        line-height: 1.2;

                                        .sub-proc {
                                                font-size: 11px;
                                                color: $c-ter-600;
                                        }
                                }

                        }


                        .table-row {
                                min-height: 55px;

                                .col-center {
                                        display: flex;
                                        justify-content: center;
                                }

                                .col-sitting {
                                        display: inline-flex;
                                        align-items: center;

                                        div {
                                                &:first-child {
                                                        margin-right: .5rem;
                                                }
                                        }
                                }

                                .col-actions {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;

                                        .izq {
                                                display: flex;

                                                div {
                                                        &:first-child {
                                                                margin-right: .5rem;
                                                        }
                                                }

                                        }
                                }

                                &.row-declined {

                                        .icon-mail,
                                        .icon-photocall,
                                        .icon-asistentes,
                                        .icon-sitting {
                                                opacity: .3;
                                        }
                                }
                        }

                        .contact-list {

                                .spacer-asistentes {
                                        margin-left: 1rem;
                                        margin-right: 0 !important;
                                }

                                .tt-into {
                                        display: flex;
                                        text-align: left;
                                        flex-direction: column;
                                        line-height: normal !important;
                                        font-size: 13px;

                                        p {
                                                margin-bottom: .2rem;

                                                &.tit {
                                                        font-weight: $fw-bold;
                                                        text-transform: capitalize;
                                                }
                                        }

                                        &.tt-confirm,
                                        &.st-enviada {
                                                .tit {
                                                        color: $c-sucs-dark;
                                                }

                                        }

                                        &.tt-out {
                                                .tit {
                                                        color: $c-ter-600;
                                                }

                                        }

                                        &.tt-cancel,
                                        &.st-modificada,
                                        &.st-error {
                                                .tit {
                                                        color: $c-error;
                                                }

                                        }
                                }
                        }
                }

        }

        &.dashboard-invitaciones {

                .full-box {
                        display: flex;
                        height: 70vh;


                        &.empty {

                                .card-body {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                }
                        }

                        .no-results {
                                text-align: center;

                                p,
                                h3 {
                                        margin-bottom: 1.2rem;
                                        color: $c-ter-600;
                                }
                        }
                }

                .inpage-nav {
                        margin-left: 12px;
                }
        }

}

.dashboard-modal-contact {
        height: calc(100vh - 110px) !important;

        .contact-read {
                margin: 32px 0;
        }

        .modal-footer {
                display: flex;
                justify-content: space-between;

                .remove {
                        .btn {
                                margin-left: 1rem;
                        }
                }

                .actions {
                        .btn {
                                &:first-child {
                                        margin-right: .5rem;
                                }
                        }
                }
        }


}


.preview-container {
        max-width: 620px;
        margin: 0 auto;


        .preview-mail {
                background-color: #fff;
                padding: 2rem 3rem;
                margin: 2rem auto;
                text-align: center;

                p {
                        margin-bottom: 0;
                }

                .header-preview {
                        border-bottom: 1px solid $c-ter-300;
                        padding-bottom: 1rem;

                        .tit {
                                display: flex;
                                font-size: 15px;
                                font-weight: $fw-heavy;
                                align-items: center;

                                i {
                                        margin-right: .7rem;
                                        font-size: 20px;
                                }
                        }

                }

                .body-preview {
                        .imagen-vacia {
                                min-height: 250px;
                                margin-top: 1rem;
                                border: 1px dashed $c-ter-400;
                                background-color: $c-ter-200;
                                border-radius: 8px;
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                i {
                                        color: $c-ter-500;
                                        font-size: 30px;
                                }

                        }
                }

                .grey-box {
                        border-radius: 7px;
                        background-color: $c-ter-300;
                        color: $c-ter-700;
                        display: flex;
                        align-items: center;
                        padding: 1rem;

                        .icon {
                                display: inline-flex;
                                margin-right: 1rem;

                                .fticon-calendar {
                                        font-size: 20px;
                                }

                                .fticon-location {
                                        font-size: 25px;
                                }
                        }

                        .info {
                                text-align: left;

                                .tit {
                                        font-size: 15px;
                                        font-weight: $fw-heavy;

                                        &::first-letter {
                                                text-transform: uppercase;
                                        }
                                }

                                .subtit {
                                        font-size: 14px;
                                }
                        }


                }

                .sign-preview {
                        padding-top: 1.7rem;
                        border-top: 1px solid $c-ter-300;


                        .row-sign {
                                text-align: left;
                                display: flex;
                                align-items: center;

                                .logo-mds {
                                        margin-right: 1rem;
                                }

                                .sign-text {
                                        line-height: 1.3;

                                        .rmt {
                                                font-size: 13px;
                                                font-weight: $fw-bold;
                                        }

                                }
                        }

                }
        }

        .preview-legal {
                font-size: 11px;
                color: $c-ter-600;
                padding: 0 2rem;
                white-space: pre-line;
                margin-bottom: 2rem;

                span,
                a {
                        color: $c-sec;
                }
        }
}



//auditoria/logs
.audits-page {

        .audit-list {
                .audit-filters {
                        margin-bottom: .5rem;
                }

                .scrollable-audits {
                        height: calc(100vh - 400px) !important;
                        min-height: 140px;
                        padding: 0 10px 0 0;
                        overflow: hidden;
                        overflow-y: scroll;

                        &.compact {
                                height: inherit !important;
                                overflow-y: auto !important;
                        }
                }
        }
}

//mapas

.map-container {

        .map-toolbar {
                margin: 15px auto;

                .boxed {
                        background-color: #fff;
                        padding: 0;
                        border-radius: 8px;
                        width: fit-content;
                }

                .w-cto {
                        &::after {
                                content: "";
                                width: 7px;
                                height: 7px;
                                border-radius: 50%;
                                background-color: $c-prim;
                                position: relative;
                                top: -20px;
                                right: -10px;
                        }

                        .fticon-edit {
                                position: relative;
                                left: 3px;
                        }

                        .fticon-setting {
                                position: relative;
                                left: 3.5px;
                        }

                        .fticon-user {
                                position: relative;
                                left: 3.5px;
                        }
                }

                .actions {
                        display: flex;
                        justify-content: center;
                        align-content: center;

                        .back {
                                display: flex;
                                align-content: center;

                                .fticon-arrow-left {
                                        font-size: 22px;
                                }
                        }

                        .info {
                                display: flex;
                                align-content: center;
                                margin: 0 1rem;

                                .seats {
                                        display: inline-flex;
                                        align-items: center;
                                        line-height: 1;

                                        div {
                                                &:first-child {
                                                        font-size: 13px;
                                                        color: $c-ter-600;
                                                        margin-right: 1.3rem;
                                                }

                                                &:last-child {
                                                        width: 60px;
                                                        font-weight: $fw-bold;
                                                        text-align: center;
                                                        margin-right: .5rem;
                                                }
                                        }
                                }

                                .room {
                                        margin-right: 3rem;
                                        font-weight: $fw-bold;

                                        .fticon-location {
                                                font-size: 22px;
                                                margin: 0 .4rem;
                                                position: relative;
                                                top: 3px;
                                        }
                                }
                        }

                        .controls {
                                display: flex;
                                align-content: center;

                                .zoom {
                                        // margin-left: .5rem;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        border-radius: 0px;

                                        .fticon-minus {
                                                font-size: 19px;

                                        }

                                        .fticon-plus {
                                                position: relative;
                                                left: -2px;

                                        }

                                        .percent {
                                                font-weight: $fw-bold;
                                                width: 50px;
                                                text-align: center;
                                                line-height: 2;
                                        }
                                }

                                .active {
                                        background-color: rgba($c-sec-dark, .16) !important;
                                        color: $c-sec-dark;
                                        text-decoration: none;
                                        border-radius: 0px;
                                }

                                .btn-pointer {
                                        border-bottom-right-radius: 0px;
                                        border-top-right-radius: 0px;
                                        border-top-left-radius: 8px;
                                        border-bottom-left-radius: 8px;

                                }

                        }

                        .room-select {
                                margin-left: 1rem;
                                display: flex;

                                .noform-select-map {
                                        margin-right: 1rem;

                                        .maproom-select__control {
                                                width: 150px;
                                                border-color: transparent !important;
                                                box-shadow: none !important;
                                                outline: none !important;

                                                .maproom-select__single-value {
                                                        font-weight: $fw-bold;
                                                        font-size: 14px;
                                                        color: $c-sec;
                                                }

                                                .maproom-select__indicator-separator {
                                                        display: none;
                                                }

                                                .maproom-select__indicator {
                                                        color: $c-sec;
                                                }

                                        }

                                }

                                .aforo {
                                        display: flex;
                                        align-items: center;
                                        font-size: 12px;
                                        color: $c-ter-600;


                                        .total {
                                                display: flex;
                                                align-items: center;
                                                margin-right: 1rem;
                                        }

                                        .sala {
                                                display: flex;
                                                align-items: center;
                                                margin-right: 1rem;
                                        }

                                        span {
                                                font-weight: $fw-bold;
                                                color: $c-ter-700;
                                        }

                                        .in-room {
                                                display: flex;
                                                align-items: center;
                                                margin-right: 1rem;

                                                .fticon-user {
                                                        margin-right: .7rem;
                                                }

                                                &.inside {
                                                        .fticon-user {
                                                                color: $c-greenlight;
                                                        }
                                                }

                                                &.pending {
                                                        .fticon-user {
                                                                color: $c-redlight;
                                                        }
                                                }

                                        }
                                }
                        }
                }

                .actions-map {
                        display: flex;
                        justify-content: flex-end;
                        gap: 1rem;
                }

                .settings {
                        display: flex;
                        justify-content: flex-end;
                }

                .dropdown-menu {
                        width: 380px;
                        transform: translateY(60px) !important;
                }

                .seat-options {
                        width: 380px;
                        position: absolute;
                        padding: 10px;
                        top: 80px;
                        border-radius: 4px;
                        box-shadow: 0 2px 11px 0 rgba(191, 203, 215, 0.35);
                        border: solid 1px $c-ter-200;
                        background-color: #fff;
                        display: none;
                        z-index: 2;

                        &.show {
                                display: block;
                        }
                }

                .drop-content {
                        padding: 10px;

                        h3 {
                                margin-bottom: 1.5rem;
                        }

                        .header {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                margin-bottom: 1rem;

                                h3 {
                                        margin-bottom: 0;
                                }
                        }

                        ul {
                                list-style: none;
                                padding-left: 0;
                                margin-bottom: 0;

                                li {
                                        background-color: $c-ter-200;
                                        border-radius: 4px;
                                        margin-bottom: .5rem;
                                        padding: 12px;
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        line-height: 1.2;
                                        cursor: pointer;
                                        color: $c-ter-700;

                                        &:hover {
                                                background-color: $c-ter-300;
                                        }

                                        &.active {
                                                box-shadow: 0 1px 7px 0 rgba(147, 166, 185, 0.5);
                                                background-color: #fff;
                                                width: 98%;
                                                margin: 0 auto .5rem auto;

                                                .circle {
                                                        border-color: $c-ter-600;
                                                }
                                        }

                                        div {
                                                &.circle {
                                                        border-radius: 50%;
                                                        border: 1px solid $c-ter-400;
                                                        padding: 4px;
                                                        margin-right: 1rem;
                                                        width: 31px;
                                                        height: 31px;

                                                        &.blue {
                                                                background-color: $c-reduced;
                                                                border-color: $c-reduced;
                                                                color: #fff;
                                                        }

                                                        &.nulo {
                                                                border: 0px;
                                                        }

                                                        &.space {
                                                                background-color: $c-ter-300;
                                                                border-color: $c-ter-300;
                                                        }

                                                        &.forbid {
                                                                background-color: transparent;
                                                        }
                                                }

                                                &.content {
                                                        display: flex;
                                                        justify-content: space-between;
                                                        width: calc(100% - 55px);

                                                        span {
                                                                &:last-child {
                                                                        color: $c-ter-600;
                                                                }
                                                        }
                                                }
                                        }

                                        i {
                                                &.fticon-seating-area {
                                                        font-size: 5px;
                                                        position: relative;
                                                        top: -1px;
                                                        left: 1px;
                                                }

                                                &.fticon-screen {
                                                        font-size: 12px;
                                                        position: relative;
                                                        top: 1px;
                                                        left: 2px;
                                                }

                                                &.fticon-move {
                                                        font-size: 12px;
                                                        position: relative;
                                                        top: 1px;
                                                        left: 2px;
                                                }

                                                &.fticon-handicapped {
                                                        position: relative;
                                                        top: 1px;
                                                        left: 1px;
                                                }

                                                &.fticon-forbidden {
                                                        font-size: 31px;
                                                        position: relative;
                                                        top: -9px;
                                                        left: -12px;
                                                        color: $c-ter-500;
                                                }
                                        }

                                        &:last-child {
                                                margin-bottom: 0;
                                        }

                                        &.fixed-section {
                                                cursor: default;

                                                &:hover {
                                                        background-color: $c-ter-200;
                                                }
                                        }
                                }

                                &.w-selection {
                                        li {
                                                opacity: .3;

                                                &.active {
                                                        opacity: 1;
                                                }
                                        }

                                }
                        }

                        .section-edit {

                                .section-row {
                                        display: flex;

                                        .color-sel {
                                                margin-right: .5rem;

                                        }

                                        .texto {
                                                margin-right: .5rem;
                                        }

                                        .actions {
                                                display: inline-flex;

                                                .btn {
                                                        margin-right: .5rem;

                                                        &:last-child {
                                                                margin-right: 0;
                                                        }
                                                }

                                        }
                                }

                                .new-section {
                                        .section-row {
                                                margin-bottom: .5rem;

                                                .texto {
                                                        width: 201px;
                                                }
                                        }
                                }
                        }
                }
        }

        .noMap {
                min-height: calc(100vh - 180px);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 2rem;
        }

        .map-scroll {
                width: 100%;
                height: calc(100vh - 84px);
                overflow: hidden;

                .map-canvas {
                        height: 5000px;
                        width: 5000px;
                        position: relative;
                        user-select: none;
                        transform-origin: 0 0;
                        -webkit-transition: .5s transform ease-in-out;
                        -moz-transition: .5s transform ease-in-out;
                        -o-transition: .5s transform ease-in-out;
                        transition: .5s transform ease-in-out;

                        .rplus {
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                .fticon-plus {
                                        color: #fff;
                                        background-color: $c-sec;
                                        font-size: 6px;
                                        padding: 2px;
                                        position: relative;
                                        left: -1px;
                                }

                                &.top {
                                        .fticon-plus {
                                                top: -6px;
                                        }
                                }

                                &.right {
                                        .fticon-plus {
                                                left: 6px;
                                        }
                                }

                                &.left {
                                        .fticon-plus {
                                                left: -6px;
                                        }
                                }

                                &.bottom {
                                        .fticon-plus {
                                                bottom: -5px;
                                        }
                                }
                        }

                        .area-elem {

                                .area-container {
                                        width: 100%;
                                        height: 100%;
                                        position: relative;
                                        padding: 0;

                                        &.area-type-screen {
                                                padding: 10px;
                                        }

                                        &.area-type-seat {
                                                padding: 10px 15px 0 0;
                                        }

                                        .cont-header {
                                                display: flex;
                                                justify-content: space-between;
                                                align-items: center;
                                                position: relative;
                                                z-index: 55;

                                                .cont-name {
                                                        padding: 1px;
                                                        width: fit-content;
                                                        display: flex;
                                                        align-items: center;
                                                        max-width: 100%;


                                                        i {
                                                                cursor: move;
                                                                color: #93a7b9;
                                                                margin-right: .3rem;
                                                        }

                                                        .seat-name {
                                                                font-weight: bold;
                                                                margin-right: 10px;
                                                                white-space: nowrap;
                                                                overflow: hidden;
                                                                text-overflow: ellipsis;
                                                        }

                                                        .seat-count {
                                                                background-color: $c-ter-600;
                                                                padding: 0 5px;
                                                                border-radius: 8px;
                                                                color: #fff;
                                                                font-size: 11px;
                                                        }
                                                }

                                                .cont-actions {
                                                        .btn {
                                                                &:first-child {
                                                                        margin-right: .5rem;
                                                                }
                                                        }
                                                }

                                        }

                                        .cont-cont {
                                                height: calc(100% - 50px);
                                                margin-top: 10px;

                                                .area-row {
                                                        display: flex;
                                                        align-items: center;
                                                        width: fit-content;

                                                        .area-row-fila {
                                                                width: 20px;
                                                                height: 23px;
                                                                display: flex;
                                                                align-items: center;
                                                                justify-content: center;
                                                                margin-right: 5px;
                                                                cursor: pointer;

                                                                span {
                                                                        font-size: 9px;
                                                                        color: $c-ter-500;
                                                                }
                                                        }

                                                        .area-row-guide {
                                                                width: 20px;
                                                                height: 23px;
                                                                display: flex;
                                                                align-items: center;
                                                                justify-content: center;
                                                                margin-top: 5px;
                                                                cursor: pointer;

                                                                &:first-child {
                                                                        margin-right: 5px;
                                                                        visibility: hidden;
                                                                        cursor: default;
                                                                }

                                                                span {
                                                                        font-size: 9px;
                                                                        color: $c-ter-500;
                                                                }
                                                        }

                                                        .area-row-seat {
                                                                width: 20px;
                                                                height: 23px;
                                                                display: flex;
                                                                align-items: center;
                                                        }

                                                        .drag-select {
                                                                opacity: .5;
                                                        }

                                                        .row-seat {
                                                                width: 16px;
                                                                height: 16px;
                                                                margin: 2px;
                                                                display: inline-flex;
                                                                align-items: center;
                                                                color: $c-ter-500;
                                                                line-height: 0;

                                                                span {

                                                                        display: flex;
                                                                        font-size: 8px;
                                                                        justify-content: center;
                                                                        align-items: flex-end;
                                                                        width: 100%;

                                                                        .fticon-tick {
                                                                                font-size: 5px;
                                                                                position: relative;
                                                                                left: -1px;
                                                                                top: 0.15vh;
                                                                        }

                                                                        &.notempty {
                                                                                font-family: "fonticon";

                                                                                &::before {
                                                                                        content: '\e81f';
                                                                                        color: #fff;
                                                                                        font-size: 5px;
                                                                                        position: relative;
                                                                                        top: 1px;
                                                                                }
                                                                        }
                                                                }

                                                                &.seat {
                                                                        border: solid 1px $c-ter-400;
                                                                        border-radius: 50%;

                                                                        &.seat-masive-selected {
                                                                                background-color: $c-prim;
                                                                                border-color: $c-prim;
                                                                                color: #fff;
                                                                        }

                                                                        &.seat-duplicated-selected {
                                                                                background-color: $c-pend;
                                                                                border-color: $c-pend;
                                                                                color: #fff;
                                                                        }

                                                                        &.no-bgs {
                                                                                .notempty {
                                                                                        &::before {
                                                                                                color: $c-ter-500;
                                                                                        }
                                                                                }
                                                                        }

                                                                }

                                                                &.space {
                                                                        border: solid 1px $c-ter-400;
                                                                        background-color: $c-ter-400;
                                                                        border-radius: 50%;

                                                                        &.seat-masive-selected {
                                                                                background-color: $c-prim;
                                                                                border-color: $c-prim;
                                                                        }
                                                                }

                                                                &.nula {
                                                                        border: solid 1px transparent;
                                                                        background-color: transparent;

                                                                        span {
                                                                                display: none;
                                                                        }

                                                                        &::after {
                                                                                font-family: "fonticon";
                                                                                content: '\e808';
                                                                                font-size: 15px;
                                                                                color: $c-ter-400;
                                                                                position: relative;
                                                                                top: 0.5px;
                                                                        }

                                                                        &.seat-masive-selected {
                                                                                background-color: $c-prim;
                                                                                border-color: $c-prim;

                                                                                &::after {
                                                                                        content: ''
                                                                                }
                                                                        }
                                                                }

                                                                &.handicap {
                                                                        background-color: $c-reduced !important;
                                                                        border: solid 1px $c-reduced !important;

                                                                        span {
                                                                                color: #fff;
                                                                        }
                                                                }

                                                                &.seat-select {
                                                                        cursor: pointer;

                                                                        &.space {
                                                                                cursor: pointer;
                                                                        }
                                                                }

                                                                &.seat-select-ev {
                                                                        cursor: pointer;

                                                                        &.space {
                                                                                cursor: default;
                                                                        }
                                                                }

                                                                &.seat-select-sitting {
                                                                        cursor: pointer;

                                                                        &.cant-seat {
                                                                                cursor: not-allowed;
                                                                        }

                                                                        &.space {
                                                                                cursor: default;
                                                                        }
                                                                }

                                                        }


                                                }

                                                .area-screen {
                                                        color: $c-ter-700;
                                                        background-color: $c-ter-400;
                                                        width: 100%;
                                                        height: 100%;
                                                        min-height: 22px;
                                                        display: flex;
                                                        justify-content: center;
                                                        align-items: center;
                                                        font-weight: $fw-bold;
                                                        font-size: 11px;
                                                        text-transform: uppercase;
                                                }

                                        }


                                }




                                &.edit-open {
                                        opacity: .5;
                                        pointer-events: none;
                                }

                                &.edit-current {
                                        opacity: 1;
                                        pointer-events: inherit;

                                        .cont-header {
                                                height: 40px;

                                                .cont-actions {
                                                        display: none;
                                                }

                                                .seat-count {
                                                        display: none;
                                                }
                                        }

                                        .area-container {
                                                border: 1px solid $c-sec;

                                                .cont-cont {
                                                        -webkit-box-shadow: inset 20px -23px 0px 0px rgba($c-ter-300, 1);
                                                        -moz-box-shadow: inset 20px -23px 0px 0px rgba($c-ter-300, 1);
                                                        box-shadow: inset 20px -23px 0px 0px rgba($c-ter-300, 1);

                                                        .area-row {

                                                                .area-row-fila {
                                                                        position: relative;
                                                                        cursor: default;

                                                                        span {
                                                                                background-color: $c-ter-400;
                                                                                width: 16px;
                                                                                height: 16px;
                                                                                color: $c-ter-400;
                                                                        }


                                                                        &::before {
                                                                                content: attr(data-rownum);
                                                                                position: absolute;
                                                                                background-color: $c-ter-300;
                                                                                width: 20px;
                                                                                height: 23px;
                                                                                display: flex;
                                                                                align-items: center;
                                                                                justify-content: center;
                                                                                font-size: 9px;
                                                                                left: 0px;
                                                                                color: $c-ter-600;
                                                                        }

                                                                }


                                                                &:last-child {

                                                                        .area-row-seat {
                                                                                position: relative;

                                                                                &::after {
                                                                                        content: attr(data-seatnum);
                                                                                        position: absolute;
                                                                                        background-color: $c-ter-300;
                                                                                        width: 20px;
                                                                                        height: 23px;
                                                                                        display: flex;
                                                                                        align-items: center;
                                                                                        justify-content: center;
                                                                                        font-size: 9px;
                                                                                        top: 30px;
                                                                                        color: $c-ter-600;
                                                                                }

                                                                                &:last-child {
                                                                                        &::after {
                                                                                                width: 33px;
                                                                                                justify-content: flex-start;
                                                                                                padding-left: 5px;
                                                                                        }
                                                                                }
                                                                        }


                                                                }
                                                        }

                                                }
                                        }
                                }

                        }


                }


                &.is-dragging {
                        cursor: grab;

                        .fticon-move {
                                cursor: inherit !important;
                        }
                }

                &.is-static {
                        cursor: default !important;
                }

                &.is-asist-open {
                        .map-canvas {
                                transform: translateX(380px);
                        }
                }

                &.has-section {
                        .map-canvas {
                                .area-elem {
                                        .area-container {
                                                .cont-header {
                                                        .cont-name {
                                                                i {
                                                                        cursor: default;
                                                                        opacity: .2;
                                                                }
                                                        }
                                                }
                                        }
                                }
                        }
                }
        }


        .plano-container {

                .asist-options {
                        width: 380px;
                        position: absolute;
                        padding: 10px;
                        top: 80px;
                        border-radius: 4px;
                        box-shadow: 0 2px 11px 0 rgba(191, 203, 215, 0.35);
                        border: solid 1px $c-ter-200;
                        background-color: #fff;
                        display: none;
                        z-index: 2;

                        &.show {
                                display: block;
                        }

                        .asist-content {
                                padding: 10px;
                        }

                        .head-popup {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                margin-bottom: 1.5rem;
                        }



                        .filter-popup {

                                .row-status {
                                        margin-bottom: 2rem;

                                        .input-type-checkbox {
                                                margin: 0;
                                                width: 100%;

                                                .form-label {
                                                        width: 100%;
                                                        display: flex;
                                                        justify-content: space-between;
                                                        align-items: center;
                                                        padding: 10px;
                                                        font-size: 13px;
                                                }
                                        }
                                }

                                .row-rooms {
                                        margin-bottom: 1rem;

                                }
                        }



                        .body-popup {

                                .table-header {
                                        .input-type-checkbox {
                                                margin-left: 10px;
                                        }
                                }

                                .asistentes-list {
                                        height: calc(100vh - 320px);
                                        overflow-y: auto;
                                        padding-right: 3px;

                                        .asistente {
                                                .asistente-row {
                                                        margin-bottom: .7rem;
                                                        border-radius: 4px;
                                                        padding: 13px 10px;
                                                        background-color: $c-ter-200;
                                                        font-size: 13px;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: space-between;
                                                        cursor: pointer;
                                                        user-select: none;

                                                        &.expanded {
                                                                box-shadow: 0 1px 7px 0 rgba(147, 166, 185, 0.5);
                                                                background-color: #fff;
                                                                margin: 0 auto 1rem auto;
                                                                position: relative;

                                                                &::before {
                                                                        content: '\25B6';
                                                                        position: absolute;
                                                                        color: $c-sec;
                                                                        left: -1px;
                                                                        font-size: 18px;
                                                                        top: 50%;
                                                                        transform: translateY(-50%);
                                                                }
                                                        }

                                                        .name {
                                                                display: flex;
                                                                align-items: center;
                                                                margin-left: 10px;

                                                                .input-type-checkbox {
                                                                        margin-right: 1rem;
                                                                }

                                                                .no-check {
                                                                        width: 32px;
                                                                }

                                                                span {
                                                                        font-weight: $fw-bold;
                                                                }
                                                        }

                                                        &.ast-pasado {
                                                                .name {
                                                                        margin-left: 0;
                                                                }

                                                                &.expanded {
                                                                        &::before {
                                                                                display: none;
                                                                        }
                                                                }
                                                        }

                                                        .info {
                                                                display: flex;

                                                                .seat {
                                                                        width: 30px;
                                                                }

                                                                .totals {
                                                                        color: $c-ter-600;
                                                                        width: 50px;
                                                                        text-align: right;

                                                                        span {
                                                                                font-weight: $fw-bold;
                                                                                color: $c-ter-700;
                                                                        }

                                                                }
                                                        }

                                                        .icon-table {
                                                                width: 36px;
                                                                height: 36px;
                                                                border-radius: 6px;
                                                                background-color: $c-ter-200;
                                                                display: flex;
                                                                align-items: center;
                                                                justify-content: center;
                                                                color: $c-ter-600;
                                                                line-height: 0;

                                                                &.icon-entrada {
                                                                        font-size: 17px;
                                                                        margin-right: .5rem;

                                                                        &.dentro {
                                                                                color: $c-sucs-dark;
                                                                                background-color: rgba($c-sucs-dark, .16);
                                                                        }
                                                                }
                                                        }
                                                }

                                                .asistente-info {
                                                        padding: 0 10px;
                                                        opacity: 0;
                                                        -moz-transition: opacity 0.3s ease-in-out;
                                                        -o-transition: opacity 0.3s ease-in-out;
                                                        -webkit-transition: opacity 0.3s ease-in-out;
                                                        transition: opacity 0.3s ease-in-out;
                                                        overflow: hidden;
                                                        height: 0;

                                                        &.opened {
                                                                opacity: 1;
                                                                height: fit-content;
                                                                margin-bottom: 1rem;
                                                        }

                                                        .w-entrada {
                                                                display: flex;
                                                                align-items: center;
                                                                background-color: rgba($c-error, .12);
                                                                border-radius: 8px;
                                                                padding: 12px 20px 12px 10px;
                                                                margin-bottom: 1rem;

                                                                i {
                                                                        font-size: 30px;
                                                                        color: $c-error;
                                                                        margin-right: .5rem;
                                                                }

                                                                p {
                                                                        margin-bottom: 0;
                                                                }
                                                        }

                                                        .sitting-type {
                                                                display: flex;
                                                                align-items: center;
                                                                justify-content: space-between;
                                                                margin-bottom: 1rem;
                                                                margin-top: .2rem;
                                                                padding: 0 8px 0 6px;

                                                                .name {
                                                                        i {
                                                                                margin-right: .5rem;
                                                                        }
                                                                }

                                                                .info {
                                                                        font-size: 13px;

                                                                        span {
                                                                                font-weight: $fw-bold;
                                                                                color: $c-ter-700;
                                                                        }
                                                                }
                                                        }

                                                        .w-seatinfo {
                                                                border-radius: 6px;
                                                                background-color: rgba($c-sec-light, .16);
                                                                padding: 0px 12px 5px;
                                                                margin-bottom: 1rem;

                                                                .head {
                                                                        display: flex;
                                                                        align-items: center;
                                                                        justify-content: space-between;
                                                                        padding: 10px 5px;

                                                                        .sala {
                                                                                font-weight: $fw-bold;
                                                                        }

                                                                        .zona {
                                                                                font-weight: $fw-bold;
                                                                                color: $c-sec;
                                                                                font-size: 13px;
                                                                        }
                                                                }

                                                                .asientos {
                                                                        display: flex;
                                                                        align-items: center;
                                                                        justify-content: space-between;
                                                                        padding: 7px 5px;
                                                                        border-top: 1px solid $c-ter-400;

                                                                        div {
                                                                                color: $c-ter-600;
                                                                                font-size: 13px;
                                                                                letter-spacing: .2px;

                                                                                span {
                                                                                        color: $c-ter-700;
                                                                                        font-weight: $fw-bold;

                                                                                        .blue {
                                                                                                color: $c-sec;
                                                                                        }
                                                                                }
                                                                        }
                                                                }

                                                                &.steps {
                                                                        padding-top: 8px;

                                                                        .input-type-step {
                                                                                .mb-3 {
                                                                                        margin-bottom: 0 !important;
                                                                                }
                                                                        }

                                                                        .step-row {
                                                                                align-items: center;

                                                                                .sala {
                                                                                        font-weight: $fw-bold;
                                                                                }

                                                                                .zona {
                                                                                        font-weight: $fw-bold;
                                                                                        color: $c-sec;
                                                                                        font-size: 12px;
                                                                                }
                                                                        }
                                                                }

                                                        }

                                                        .section-save {
                                                                text-align: right;
                                                        }

                                                        &.ast-info-pasado {

                                                                .w-seatinfo {

                                                                        .head {

                                                                                .zona {
                                                                                        margin-left: 1rem;
                                                                                }
                                                                        }

                                                                }

                                                        }


                                                }




                                        }



                                        .no-results {
                                                text-align: center;

                                                p,
                                                h3 {
                                                        margin-bottom: 1.2rem;
                                                        color: $c-ter-600;
                                                }
                                        }
                                }
                        }
                }

                .drop-content {
                        ul {
                                max-height: calc(100vh - 220px);
                                overflow-y: auto;
                        }
                }



                .map-scroll {
                        .map-canvas {
                                .area-elem {
                                        .area-container {

                                                .cont-header {
                                                        justify-content: flex-start;

                                                        .cont-name {
                                                                margin-right: 1rem;

                                                                &.cont-planos {
                                                                        margin-left: 28px;
                                                                }

                                                        }


                                                        .aforo {
                                                                display: flex;
                                                                align-items: center;
                                                                font-size: 12px;
                                                                color: $c-ter-600;


                                                                .total {
                                                                        display: flex;
                                                                        align-items: center;
                                                                        margin-right: 1rem;
                                                                }

                                                                .sala {
                                                                        display: flex;
                                                                        align-items: center;
                                                                        margin-right: 1rem;
                                                                }

                                                                span {
                                                                        font-weight: $fw-bold;
                                                                        color: $c-ter-700;
                                                                }
                                                        }
                                                }

                                                .cont-cont {
                                                        .area-row {
                                                                .row-seat {
                                                                        &.space {
                                                                                background-color: transparent;
                                                                                border-color: transparent;
                                                                        }
                                                                }
                                                        }
                                                }
                                        }
                                }
                        }
                }

                .st-tooltip {
                        font-size: 13px;

                        .section {
                                font-weight: $fw-bold;

                        }

                        .info {
                                .zona {
                                        font-weight: $fw-bold;
                                        color: $c-ter-700;

                                }

                                .zona-st {
                                        display: flex;
                                        align-items: center;

                                        span {
                                                &:first-child {
                                                        margin-right: 1rem;
                                                }
                                        }

                                }

                        }

                        .asist {
                                border-top: 1px solid $c-ter-400;
                                padding-top: .5rem;
                                margin-top: .5rem;
                                font-weight: $fw-bold;
                                display: flex;
                                align-items: center;

                                .bullet-s {
                                        width: 15px;
                                        height: 15px;
                                        border-radius: 50%;
                                        margin-right: .5rem;
                                        color: #fff;

                                        i {
                                                font-size: 5px;
                                                position: relative;
                                                top: -5px;
                                                left: 3px;
                                        }
                                }
                        }
                }

                &.is-bulk-open {
                        .asist-options {
                                .asist-content {
                                        max-height: calc(100vh - 270px);
                                        overflow: hidden;
                                        overflow-y: auto;
                                }

                                .body-popup {
                                        .asistentes-list {
                                                max-height: inherit;
                                        }
                                }
                        }
                }
        }
}


.users-page {

        .user-list {
                .user-filters {
                        margin-bottom: .5rem;
                }

                .scrollable-user {
                        height: calc(100vh - 400px) !important;
                        min-height: 140px;
                        padding: 0 10px 0 0;
                        overflow: hidden;
                        overflow-y: scroll;

                        &.compact {
                                height: inherit !important;
                                overflow-y: auto !important;
                        }
                }
        }
}

.user-read {
        text-align: center;
        margin: 32px;

        .medal {
                position: relative;
                top: -35px;
                width: 56px;
                height: 56px;
                margin-bottom: -50px;
                font-size: 18px;
        }

        .sticker {
                margin: 3px;
        }

        .user-details {
                font-size: 13px;
                color: $c-ter-600;
                margin-top: 1.5rem;

                p {
                        margin-bottom: .2rem;
                }
        }

        .user-companydetails {
                font-size: 13px;
                color: $c-ter-600;
                margin-bottom: 1.5rem;

                p {
                        margin-bottom: .2rem;
                }
        }
}


//invitaciones

.preview-invitacion {
        .modal-body {
                padding: 0 11px !important;
        }

        .preview-data-mail {
                padding: 1rem;

                .tit {
                        display: flex;
                        font-size: 15px;
                        font-weight: $fw-heavy;
                        align-items: center;
                        margin: 20px 0px 14px 0px;

                        i {
                                margin-right: .7rem;
                                font-size: 20px;
                        }
                }

                .variable-nombre {
                        padding: 0px 0px 0px;
                        font-size: 11px;
                        font-weight: $fw-bold;
                        color: $c-sec;
                        border: 0px;
                        background-color: #ffffff;
                        outline: none;
                        box-shadow: none;
                }
        }

        .Rectngulo-271 {
                padding-top: 1.7rem;
                border-top: 1px solid $c-ter-300;
                margin-top: 1.7rem;
        }

        .file-attached {
                display: flex;
                align-items: center;
                padding: 10px 0;
                border-radius: 8px;

                .file-name {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                }

                &:nth-of-type(even) {
                        background-color: rgba($c-sec, .07);
                }

                .btn-icon {
                        font-size: 11px;
                        width: 30px;
                        height: 30px;
                        line-height: 0;
                }
        }
}

.destinatarios-invitacion {

        .invitados-tit {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 1.5rem;
        }

        .full-box {
                display: flex;
                height: 70vh;


                &.empty {

                        .card-body {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                        }
                }

                .no-results {
                        text-align: center;

                        p,
                        h3 {
                                margin-bottom: 1.2rem;
                                color: $c-ter-600;
                        }
                }

        }

        .contact-list {
                height: 100% !important;
                overflow: hidden;

                .col-opts {
                        .icon-mail {
                                margin-left: 15px;

                                &.invi-sent {
                                        color: $c-sucs;
                                }
                        }
                }

                .scrollable-contacts {
                        height: calc(100vh - 280px) !important;
                        min-height: 100px;
                        padding: 0 10px 0 0;
                        overflow: hidden;
                        overflow-y: auto;
                        max-height: 55vh;

                        &.compact {
                                height: inherit !important;
                                overflow-y: auto !important;
                        }

                        .table-row {
                                cursor: default;

                                .fticon-close {
                                        font-size: 9px;
                                }
                        }

                        .icon-entrada {
                                margin-left: 15px;
                        }
                }

        }

}

.dashboard-sent-invitation {

        .container-fluid {
                max-width: 80%;
                margin: 0 auto;
        }

        .padded-head {
                padding: 30px 30px 0 30px;
        }

        .padded-body {
                padding: 0 30px;
        }

        .header-filter {

                .filter-box {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        //cursor: pointer;
                        user-select: none;

                        .status {
                                font-weight: $fw-bold;

                        }

                        .info {
                                .badge {
                                        border-radius: 10px;

                                        &.sent {
                                                background-color: rgba($c-ter-600, .12);
                                                color: $c-ter-600;
                                        }

                                        &.bounced {
                                                background-color: rgba($c-error, .14);
                                                color: $c-error;
                                        }

                                        &.opened {
                                                background-color: rgba($c-sucs-dark, .16);
                                                color: $c-sucs-dark;
                                        }

                                        &.unopen {
                                                background-color: rgba($c-sec, .12);
                                                color: $c-sec;
                                        }

                                }
                        }


                }

                &.filtered {
                        .card {
                                opacity: .5;

                                &.filter-active {
                                        opacity: 1;
                                }
                        }

                }
        }

        .header-sentfilter {

                .filter-scroll {
                        width: 100%;
                        overflow: hidden;
                        overflow-x: auto;
                        display: flex;
                        justify-content: space-between;

                        .filter-box {
                                min-width: 160px;
                                width: max-content;
                                margin-right: 1rem;
                                flex-grow: 1;


                                .card {
                                        border: 1px solid transparent;
                                        cursor: pointer;
                                }

                                &:last-child {
                                        margin-right: 0;
                                }

                                .filter-card {
                                        display: flex;
                                        justify-content: space-between;

                                        .status {
                                                font-weight: $fw-bold;
                                                font-size: 14px;
                                                color: $c-ter-700;
                                        }

                                        .badge {
                                                border-radius: 10px;
                                                align-items: center;
                                                justify-content: center;
                                                display: flex;

                                                &.ft-enviadas,
                                                &.ft-abiertas {
                                                        background-color: rgba($c-greenforest, .16);
                                                        color: $c-greenforest;
                                                }

                                                &.ft-errorenv,
                                                &.ft-rebotadas {
                                                        background-color: rgba($c-prim, .14);
                                                        color: $c-prim;
                                                }


                                                &.ft-enviando {
                                                        background-color: rgba($c-sec, .14);
                                                        color: $c-sec;
                                                }

                                                &.ft-sinabrir,
                                                &.ft-sinenviar {
                                                        background-color: rgba($c-sec, .12);
                                                        color: $c-sec;
                                                }

                                        }

                                }
                        }

                        &.filtered {
                                .card {
                                        &.filter-active {
                                                border: 1px solid $c-ter-400;
                                        }
                                }

                        }
                }

        }

        .full-box {
                display: flex;
                height: 70vh;


                &.empty {

                        .card-body {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                        }
                }

                .no-results {
                        text-align: center;

                        h3 {
                                margin-bottom: 0;
                                color: $c-ter-600;
                        }
                }

                .table-row {
                        cursor: default;
                        min-height: 54px;
                }
        }

        .info-mail {
                .col-md-2 {
                        font-weight: $fw-bold;
                }
        }


}



.nueva-invitacion {
        max-width: 1024px;
        margin: 20px auto;

        .box-list {
                .row {
                        align-items: center;

                        .form-floating,
                        .input-select__control {
                                margin-bottom: 0 !important;
                        }
                }

                .card-body {
                        min-height: 72px;
                }

                .bullet-sep {

                        &::before {
                                content: "·";
                                margin: 0 10px;
                        }
                }

                .col-text {
                        display: flex;
                        align-items: center;

                        p {
                                margin-bottom: 0;
                        }

                        i {
                                color: $c-error;
                                font-size: 16px;
                                margin-left: 0.4rem;
                        }
                }

        }

        .check-invitacion {
                margin: 11px 0px 0px 0;
                opacity: 0.3;
                color: #93a6b9;
                font-size: 18px;

                &.cambio-check {
                        opacity: 10;
                        color: #6dd845;
                }
        }


        .tick-close {
                align-items: center;
                font-size: 9px;

                &.fticon-tick {
                        position: relative;
                        left: -2px;
                }
        }

        .deshabilitar-campos {
                opacity: .5;
                pointer-events: none;
        }

        .variable-nombre {
                font-size: 11px;
                font-weight: $fw-bold;
                color: $c-sec;
                border: 0;
                background-color: transparent;
        }

}

.send-invitation-modal {
        .swal2-title {
                font-size: 18px;
        }

        .swal2-html-container {
                font-size: 14px;
        }
}

//Entradas
.row-qr-header {
        align-items: center;

        .div-qr {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-bottom: 1rem;

                .qrs-generados {
                        padding: 12px 25px;
                        border-radius: 8px;
                        border: solid 1px #bfcbd7;
                }
        }

}

.event-entrada-modal {
        margin-bottom: 0 !important;
        padding: 20px 30px !important;
        display: flex;
        justify-content: center;

        .row-entrada {
                width: 620px;
                height: 810px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;

                .poster-entrada {
                        padding: 15px;

                        .spacer-entrada {
                                height: 495px;
                        }

                        .contenedor-entrada-qr {
                                padding: 20px 20px;
                                border-radius: 10px;
                                background-color: #fff;
                                color: $c-ter-700;

                                .info-entrada {
                                        display: flex;
                                        margin-bottom: 20px;

                                        .detalles {
                                                width: calc(100% - 150px);

                                                .fecha-entrada {
                                                        font-weight: $fw-bold;
                                                        margin-bottom: 0;
                                                        font-size: 14px;
                                                }

                                                .titulo-entrada {
                                                        font-size: 24px;
                                                        font-weight: $fw-heavy;
                                                        margin-bottom: .2rem;
                                                }

                                                .lugar-entrada {
                                                        font-size: 14px;
                                                }

                                                .div-entrada {
                                                        display: flex;
                                                }

                                                .texto-entrada {
                                                        font-size: 12px;
                                                        line-height: 1.4;
                                                }
                                        }

                                        .qr {
                                                width: 150px;
                                                display: flex;
                                                align-items: center;

                                                i {
                                                        font-size: 110px;
                                                        color: #000;
                                                        line-height: 0;
                                                        padding: 10px;
                                                }

                                        }
                                }

                                .line {
                                        height: 1px;
                                        margin: 10px 0;
                                        border-bottom: 1px dashed $c-ter-500;
                                }

                                .seat-info {
                                        display: flex;
                                        gap: 1rem;

                                        .site {
                                                display: flex;
                                                flex-direction: column;

                                                .tit {
                                                        font-weight: $fw-bold;
                                                        margin-bottom: .5rem;
                                                        font-size: 14px;
                                                }

                                                .box {
                                                        padding: 7px 0 7px;
                                                        border-radius: 4px;
                                                        border: solid 1px $c-ter-300;
                                                        text-align: center;
                                                        background-color: $c-ter-300;
                                                }

                                                &.sala {
                                                        width: 35%;

                                                        .box {
                                                                border-color: $c-electricblue;
                                                                background-color: $c-electricblue;
                                                                color: #fff;
                                                        }

                                                }

                                                &.zona {
                                                        width: 35%;

                                                        .box {
                                                                background-color: $c-purple;
                                                                border-color: $c-purple;
                                                                color: #fff;
                                                        }
                                                }

                                                &.fila {
                                                        width: 15%;
                                                }

                                                &.butaca {
                                                        width: 15%;
                                                }

                                        }

                                        &.no-sitting {
                                                .site {

                                                        &.sala,
                                                        &.zona {
                                                                width: 50%;
                                                        }
                                                }
                                        }

                                }

                        }
                }
        }

}

.event-unify-modal {

        .list-contacts {
                .contact-tounify {
                        background-color: $c-ter-100;
                        border-radius: 6px;
                        padding: 1.5rem 2rem;
                        margin-bottom: 2rem;

                        .rep {
                                font-weight: $fw-bold;
                        }

                        .select {
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
                                margin-bottom: .7rem;

                                span {
                                        font-size: 12px;
                                        font-weight: $fw-bold;
                                        margin-right: 1rem;
                                }

                                .input-type-checkbox {
                                        margin-right: 1.07rem;
                                }
                        }

                        .mail-list {
                                .contact {
                                        padding: .7rem 1rem;
                                        background-color: #fff;
                                        border-top: solid 1px $c-ter-300;
                                        border-left: solid 1px $c-ter-300;
                                        border-right: solid 1px $c-ter-300;
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;

                                        &:first-child {
                                                border-top-left-radius: 6px;
                                                border-top-right-radius: 6px;
                                        }

                                        &:last-child {
                                                border-bottom-left-radius: 6px;
                                                border-bottom-right-radius: 6px;
                                                border-bottom: solid 1px $c-ter-300;
                                        }

                                        .name {
                                                .fticon-user {
                                                        margin-right: .5rem;
                                                }
                                        }


                                }
                        }
                }
        }

}

/* contextify */
.contexify {

        .contexify_itemContent:hover,
        .contexify_itemContent:focus {
                background-color: $c-prim !important;
        }
}